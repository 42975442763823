<template>
  <div class="Groups-add ">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <header-pages :showButton="false" link="/edit-header" title="تعديل  هيدر "/>
    </div>
    <form @submit.prevent="updateheader">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
                id="avatar"
                ref="fileInput"
                accept="image/*"
                style="display: none"
                type="file"
                @change="handleFileChange"
            />
            <div
                v-if="!form.imageSrc"
                class="upload-icon"
                @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview"/>
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <span v-if="v$.form.imageSrc.$error" class="error-feedback">{{
                getErrorMessage(v$.form.imageSrc)
              }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">عنوان الهيدر</label>
          <div class="input">
            <input
                id="name"
                v-model="form.description "
                placeholder="اكتب هنا "
                type="text"
            />
          </div>
          <span v-if="v$.form.description.$error" class="error-feedback">{{
              getErrorMessage(v$.form.description)
            }}</span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">عنوان الهيدر</label>
          <div class="input">
            <input
                id="name"
                v-model="form.title "
                placeholder="اكتب هنا "
                type="text"
            />
            <span v-if="v$.form.title.$error" class="error-feedback">{{
                getErrorMessage(v$.form.title)
              }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">وصف الهيدر</label>
          <div class="input">
            <input
                id="name"
                v-model="form.subtitle"
                placeholder="اكتب هنا "
                type="text"
            />

            <span v-if="v$.form.subtitle.$error" class="error-feedback">{{
                getErrorMessage(v$.form.subtitle)
              }}</span>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" type="submit" @click="Edit()">تعديل</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import {useHeaderEditStore} from "@/stores/Header/HeaderEditStore";
import Swal from "sweetalert2";
import useVuelidate from "@vuelidate/core";
import {required} from "@vuelidate/validators";

export default {
  components: {HeaderPages},

  data() {
    return {
      v$: useVuelidate(),

      form: {
        id: null,
        imageSrc: "",
        title: "",
        subtitle: "",
        description: "",
      },
    };

  },

  validations() {
    return {
      form: {
        imageSrc: {required},
        title: {required},
        subtitle: {required},
        description: {required},
      },
    };
  },
  computed: {
    header() {
      return useHeaderEditStore().header;
    },
  },

  methods: {

    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    async fetchEditData() {
      const store = useHeaderEditStore();
      const id = this.$route.params.id;
      await store.getHeader(id);
      this.form = {...store.header};
      this.form.imageSrc = store.header.image;


    },

    async updateheader() {
      if (
          !this.form.title ||
          !this.form.subtitle ||
          !this.form.description ||
          !this.form.image
      ) {
        Swal.fire("Error", "Please fill in all fields", "error");
        return;
      }

      const store = useHeaderEditStore();
      const formData = new FormData();

      formData.append("id", this.form.id);
      formData.append("title", this.form.title);
      formData.append("subtitle", this.form.subtitle);
      formData.append("description", this.form.description);
      if (this.form.image instanceof File) {
        formData.append("image", this.form.image);
      }
      // formData.append("image", this.form.image);

      await store.updateHeader(formData);

    },
    Edit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
  },

  mounted() {
    this.fetchEditData();
  },
};
</script>

<style>
.error-feedback {
  color: red;
  font-size: 0.85rem;
}
</style>
