import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const usecategoryAddStore = defineStore("categoryAdd", {
  state: () => {
    return {};
  },
  actions: {
    async addCategory(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
          } else {
            // Skip imageSrc to avoid sending it

            // Append other fields
            formData.append(key, data[key]);
          }
        });

        // Send the FormData to the API
        const response = await axios.post("add_library_category", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة القسم بنجاح",
          });
          router.push("/index-category");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
  },
});
