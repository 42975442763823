import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";

export const useOpinionClientStoreeditStore = defineStore(
  "opinionClientStoreedit",
  {
    state: () => {
      return {
        OpinionClientStore: {},
      };
    },
    actions: {
      async getOpinionClientStore(id) {
        try {
          const response = await axios.post("fetch_opinion_details", {
            id: id,
          });
          this.OpinionClientStore = response.data.data;
          // console.log(this.OpinionClientStore);
        } catch (error) {
          console.error(error);
        }
      },
      async editOpinionClientStore(data) {
        try {
          const formData = new FormData();
          console.log(data, "data");

          // Loop through data to populate FormData
          Object.keys(data).forEach((key) => {
            if (Array.isArray(data[key])) {
              // Handle arrays (if needed in the future)
            } else {
              // Skip imageSrc to avoid sending it
              if (key === "imageSrc") return;

              // Only append the file if it's an instance of File
              if (key === "image") {
                if (key instanceof File) {
                  formData.append(key, data[key]);
                }
              } else {
                // Append other fields normally
                formData.append(key, data[key]);
              }
            }
          });

          // Send the FormData to the API
          const response = await axios.post("edit_opinion", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          if (response.data.status === true) {
            Swal.fire({
              icon: "success",
              title: "تم التعديل بنجاح",
              text: response.data.message || "تم تعديل التعليق بنجاح",
            });
            router.push("/opinion-client");
            return response.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: response.data.message,
            });          }
        } catch (error) {
          console.error("Error editing OpinionClientStore:", error);
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });        }
      },
    },
  }
);
