import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useBlogAddStore = defineStore("blogAdd", {
  state: () => ({}),
  actions: {
    async addBlogCategory(data) {
      this.loading = true;
      try {
        const response = await axios.post("add_blog_category", {
          title: data.title,
        });
        this.blog = response.data;
        this.loading = false;
        Swal.fire({
          icon: "success",
          title: "تم الحفظ بنجاح",
          text: response.data.message || "تم إضافة القسم بنجاح",
        });
        router.push("/index-department");
      } catch (error) {
        this.loading = false;
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
        console.log(error);
      }
    },
  },
});
