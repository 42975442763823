<template>
  <div class="blog">
    <header-pages
      title="عرض المدونه"
      button="    + إضافه مدونه "
      link="/add-blog"
      icon="fa-solid fa-globe"
    ></header-pages>
    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن مدونه..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="handleDeleteBlog"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { useBlogs } from "@/stores/blogs/BlogStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { debounce } from "lodash"; // استيراد دالة debounce

import PaginationPage from "@/components/pagination/PaginationPage.vue";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["	Id", "الصورة", "الاسم", "	وصف", "التصنيف", "القسم"],
      editLink: "/edit-blog",
      viewLink: "/view-group",
    };
  },

  computed: {
    ...mapState(useBlogs, {
      blogs: (state) => state.blogs,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      return this.blogs.map((emp) => [
        emp.id,
        emp.image,
        emp.title,
        emp.description,
        `${emp.blog_categories.map((item) => item.title).join(", ")}`,
        `${emp.blog_hashtags.map((item) => item.title).join(", ")}`,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const blogsStore = useBlogs();
      blogsStore.getBlogs(page);
    },
    async handleDeleteBlog(id) {
      const employeesStore = useBlogs();
      console.log(id);

      await employeesStore.deleteBlog(id);
    },
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const blogsStore = useBlogs();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await blogsStore.getBlogs(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await blogsStore.getBlogs(1, this.word);

      if (blogsStore.blogs.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
  },

  async mounted() {
    const employeesStore = useBlogs();
    await employeesStore.getBlogs();
    this.debouncedSearch = debounce(() => {
      this.handleSearch();
    }, 700);
  },
};
</script>

<style scoped lang="scss">
.blog.tables-page-vue.table tbody tr td {
  width: 0px !important;
}

</style>
