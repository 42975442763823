<template>
  <div class="employees-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
    <HeaderPages :showButton="false" title="اضافة مسابقة" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label> اضافة  مسابقة</label>
          <input
            id="avatar"
            ref="fileInput"
            accept="image/*"
            style="display: none"
            type="file"
            @change="handleFileChange"
          />
          <div v-if="!form.image" class="upload-icon" @click="triggerFileInput">
            <i class="fa fa-camera"></i>
            <span>اختيار صورة</span>
          </div>
          <!-- <span class="error-feedback" v-if="v$.form.image.$error">{{
              getErrorMessage(v$.form.image)
            }}</span> -->
          <div v-if="image" class="avatar-preview">
            <img :src="image" alt="Avatar Preview" />
            <i class="fa fa-times delete-icon" @click="removeImage"></i>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المسابقة</label>
          <div class="input">
            <input
              id="name"
              v-model="form.name"
              placeholder="أدخل أسم الأمتحان"
              type="text"
            />
            <span class="error-feedback" v-if="v$.form.name.$error">{{
              getErrorMessage(v$.form.name)
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_date">تاريخ البدايه</label>
          <div class="input">
            <input
              id=""
              v-model="form.start_date"
              name="start_date"
              type="date"
            />
            <span class="error-feedback" v-if="v$.form.start_date.$error">{{
              getErrorMessage(v$.form.start_date)
            }}</span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ النهايه </label>
          <div class="input">
            <input v-model="form.end_date" type="date" />
            <span class="error-feedback" v-if="v$.form.end_date.$error">{{
              getErrorMessage(v$.form.end_date)
            }}</span>
          </div>
        </div>

        <div
          v-for="(item, index) in form.rewards"
          :key="index"
          class="col-lg-4 col-md-6 col-12"
        >
          <label for="reward1">الجائزة {{ index + 1 }}</label>
          <div class="input">
            <input
              id="reward1"
              v-model="item.reward"
              placeholder="أدخل الجائزة"
              type="number"
            />
          </div>
        </div>

        <div class="col-lg-12 col-md-7 col-12">
          <label for="name"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea
              id="w3review"
              v-model="form.description"
              name="w3review"
            ></textarea>
            <span class="error-feedback" v-if="v$.form.description.$error">{{
              getErrorMessage(v$.form.description)
            }}</span>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useCompetitionAddStore } from "@/stores/competition/CompetitionAddStore";
import { mapActions } from "pinia";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "GroupsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      image: null,
      form: {
        name: "",
        start_date: "",
        end_date: "",
        description: "",
        // image: null,
        rewards: [
          {
            stage: 1,
            reward: "",
          },
          {
            stage: 2,
            reward: "",
          },
          {
            stage: 3,
            reward: "",
          },
        ],
      },
    };
  },

  validations() {
    return {
      form: {
        name: { required },
        start_date: { required },
        end_date: { required },
        description: { required },
        // image: { required },
        rewards: { required },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    ...mapActions(useCompetitionAddStore, ["addCompetition"]),
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.image = URL.createObjectURL(file);
      this.form.image = file;
    },
    removeImage() {
      this.image = null;
      this.form.image = null;
    },
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.addCompetition(this.form);
    },
  },
};
</script>
<style scoped>
.upload-icon {
  margin-top: 0.4rem;
}
</style>