<template>
  <div class="employees">
    <HeadersPages
      title=" اراء العملاء"
      button="+ اضافة  اراء العملاء"
      link="/add-opinion-client"
      icon="fa-solid fa-globe"
    />

    <div class="alll">
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="deleteopinionClient"
      :ismaster="ismaster"
    />
    <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>

  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useOpinionClientStoreStore } from "@/stores/opinionClient/opinionClientStoreIndex";
// import { usePaginationStore } from "@/stores/pagination/PaginationStore";
// import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    // PaginationPage,
  },
  data() {
    return {
      tableHeaders: ["ID", "الصور", "اسم الطالب", " الوصف"],
      editLink: "/edit-opinion-client",
      viewLink: "/view-opinion-client",
    };
  },
  computed: {
    ...mapState(useOpinionClientStoreStore, {
      opinionClientStore: (state) => state.opinionClientStore,
      // ...mapState(usePaginationStore, {
      //   paginationCurrent: (state) => state.current_page,
      //   paginationFrom: (state) => state.from,
      //   paginationLast: (state) => state.last_page,
      //   paginationPer: (state) => state.per_page,
      //   paginationTo: (state) => state.to,
      //   paginationTotal: (state) => state.total,
      // }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.opinionClientStore, "ssss");
      return this.opinionClientStore.map((emp) => [
        emp.id,
        emp.image,
        emp.name,
        emp.description,
      ]);
    },
    // tablePages() {
    //   return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    // },
  },
  methods: {
    // handlePageChange(page) {
    //   const opinionClientStoreStore = useOpinionClientStoreStore();
    //   opinionClientStoreStore.fetchOpinionClientStore(page);
    // },
    async deleteopinionClient(id) {
      const opinionClientStoreStore = useOpinionClientStoreStore();
      console.log(id);

      await opinionClientStoreStore.deleteOpinionClientStore(id);
    },
  },

  async mounted() {
    const opinionClientStoreStore = useOpinionClientStoreStore();
    await opinionClientStoreStore.fetchOpinionClientStore();
  },
};
</script>
