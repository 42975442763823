import axios from "axios";
import { Chart } from "chart.js";
import { defineStore } from "pinia";

export const useHomeStore = defineStore("home", {
  state: () => ({
    Counts: {
        parent_count: 0,
        student_count: 0,
        teacher_count: 0,
      },
    latest_students: [],
    most_active: [],
    best_places: [],
    interacted_rate: [],
    ChartData : [],
  }),
  actions: {
    async fetchCount() {
      try {
        const response = await axios.post("organization_fetch_count_statistics");
        if (response.data.status === true) {
            this.Counts.parent_count = response.data.data.parent_count;
            this.Counts.student_count = response.data.data.student_count;
            this.Counts.teacher_count = response.data.data.teacher_count;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchMostActive() {
        try {
          const response = await axios.post("organization_fetch_most_active_groups_statistics");
          if (response.data.status === true) {
            this.most_active = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async fetchLateststudents() {
        try {
          const response = await axios.post("organization_fetch_latest_students_statistics");
          if (response.data.status === true) {
            this.latest_students = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async fetchBestPlaces() {
        try {
          const response = await axios.post("organization_fetch_best_places_interacted_with_organization_statistics");
          if (response.data.status === true) {
            this.best_places = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
      async fetchInteractedRate() {
        try {
          const response = await axios.post("organization_fetch_interacted_rate_with_organization_statistics");
          if (response.data.status === true) {
            this.ChartData = response.data.data;
          }
        } catch (error) {
          console.error(error);
        }
      },
  },
});
