import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useParentsAddStore = defineStore("EmployeesAdd", {
  state: () => ({
    Parents: [],
    countries: [],
    bloods: [],
    groups: [],
  }),
  actions: {
    async fetchcountries() {
      try {
        const response = await axios.get("organization_fetch_countries");
        if (response.data.status === true) {
          this.countries = response.data.data;
          console.log(this.countries, "sssss");
        } else {
          console.log("Error fetching countries");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchblood() {
      try {
        const response = await axios.get("organization_fetch_blood_types");
        if (response.data.status === true) {
          this.bloods = response.data.data;
        } else {
          console.log("Error fetching bloods");
        }
      } catch (error) {
        console.log(error);
      }
    },
    async fetchgroup() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status === true) {
          this.groups = response.data.data;
        } else {
          console.log("Error fetching bloods");
        }
      } catch (error) {
        console.log(error);
      }
    },

    async addParents(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (key !== "imageSrc") {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        });
        const response = await axios.post("add_user", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log("API Response:", response.data);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة ولي الامر بنجاح",
          });
          router.push("/index-parent");
          this.Parents.push(response.data.data); 
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error saving employee:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
  },
});
