<template>
    <div class="jops-add">
      <div class="headers-add">
        <i class="fa-solid fa-plus add"></i>
        <header-pages title="تعديل الوظيفه" :showButton="false" />
      </div>
      <form action="" @submit.prevent="updateheader">
        <div class="row">
          <div class="col-lg-6 col-md-6 col-12">
            <label for="name">أسم الوظيفه</label>
            <div class="input">
              <input
                v-model="title"
                placeholder="أدخل أسم الوظيفه"
                type="text"
              />
            </div>
            <span class="error-feedback" v-if="v$.title.$error">
              {{ getErrorMessage(v$.title) }}
            </span>
          </div>
        </div>
        <div class="all-btn mt-4">
          <button type="submit" class="save" @click="Edit()">تعديل</button>
          <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
        </div>
      </form>
    </div>
  </template>
    
    <script>
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  import useVuelidate from "@vuelidate/core";
  import { required } from "@vuelidate/validators";
  import { useJopsEditStore } from "@/stores/jopsType/JopsEditStore";
  
  export default {
    components: { HeaderPages },
    data() {
      return {
        store: useJopsEditStore(),
        v$: useVuelidate(),
       
          title: "",
      };
    },
    validations() {
      return {
    
          title: { required },
   
      };
    },
 
    methods: {
      getErrorMessage(field) {
        if (field.$invalid && field.$dirty) {
          return "هذا الحقل مطلوب";
        }
        return "";
      },
  
  
      async fetchEditData() {
  const store = useJopsEditStore();
  const id = this.$route.params.id;
  await store.getJops(id);
  this.title = this.store.jops.title;  
},


    async updateheader() {
     

      const store = useJopsEditStore();
      const formData = new FormData();
      const id = this.$route.params.id;
      formData.append("id", id);
      formData.append("title", this.title);
      await store.updateJops(formData);

    },
  
      Edit() {
        this.v$.$validate();
        if (!this.v$.$error) {
          console.log("no error");
        }
      },
    },
    mounted() {
    this.fetchEditData();
  },
  };
  </script>
    
    <style scoped>
  button.save {
    width: 20%;
  }
  </style>