import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useScreenAddStore = defineStore("screenAdd", {
    state: () => ({
        screens: [],
    }),
 actions :{
    async Addscreens(ScreenData) {
        try {
          const formData = new FormData();
          Object.keys(ScreenData).forEach((key) => {
            if (Array.isArray(ScreenData[key])) {
                ScreenData[key].forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, ScreenData[key]);
            }
          });
  
          const response = await axios.post("add_screen", formData, {
            screens: { "Content-Type": "multipart/form-data" },
          });
  
          if (response.data.status) {
            this.screens.push(response.data.data.data); 
            Swal.fire({
              icon: "success",
              title: "تم الحفظ بنجاح",
              text: response.data.message || "تم إضافة الاسكرين بنجاح",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "Error",
              text: response.data.message,
            });
          }
        } catch (error) {
          console.error("Error while adding screens:", error);
          Swal.fire({
            icon: "error",
            title: "Error",
            text: error.response?.data?.message || "An unexpected error occurred.",
          });
        }
      },
 }
})