<template>
    <div class="relation">
      <header-pages
        title="صله القرابه "
        button="+ اضافة صله قرابه"
        link="/relation-add"
        icon="fa-solid fa-hand-holding-heart"
      />
  
      <div class="alll">
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="handleDeleteRelation"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  
  
    </div>
  </div>
  
  </template>
  
  <script>
  import TablesPageVue from "@/components/tables/TablesPage.vue";
  import { mapState } from "pinia";
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  import {useRelationsStore} from "@/stores/Relations/RelationsStore";


  
  
  export default {
    components: { HeaderPages, TablesPageVue },
    data() {
      return {
        errorMessage: "",
        tableHeaders: ["ID", "الاسم"],
        editLink: "/relation-edit",
        viewLink: "/",
      };
    },
    computed: {
      ...mapState(useRelationsStore, {
        relations: (state) => state.relations,
      }),

      tableRows() {
      const dataToDisplay = this.relations;
      return dataToDisplay.map((rel) => [
        rel.id,
        rel.title,
 
      ]);
    },

    //   tableRows() {
    //     if (!Array.isArray(this.relations)) {
    //       console.warn("relation is not an array:", this.relations);
    //       return [];
    //     }
    //   },
    //   tablePages() {
    //     return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    //   },
    },
    methods: {
      handlePageChange(page) {
        const relationStore = useRelationsStore();
        relationStore.fetchRelations(page);
      },
      handleDeleteRelation(id) {
        const relationStore = useRelationsStore();
        relationStore.deleteRelation(id);
      },
    },
    mounted() {
      const relationStore = useRelationsStore();
      relationStore.fetchRelations();
  
    },
  };
  </script>
  
  <style scoped>

  </style>
  