import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useServesAddStore = defineStore("servesAdd", {
    state: () => ({
        serves: [],
    }),

    actions: {
        async addServes(ServesData) {
            try {
              const formData = new FormData();
              Object.keys(ServesData).forEach((key) => {
                if (Array.isArray(ServesData[key])) {
                    ServesData[key].forEach((item) => {
                    formData.append(`${key}[]`, item);
                  });
                } else {
                  formData.append(key, ServesData[key]);
                }
              });
      
              const response = await axios.post("add_service", formData, {
                screens: { "Content-Type": "multipart/form-data" },
              });
      
              if (response.data.status === true) {
                this.serves.push(response.data.data.data); 
                Swal.fire({
                  icon: "success",
                  title: "تم الحفظ بنجاح",
                  text: response.data.message || "تم إضافة الخدمة بنجاح",
                });
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Error",
                  text: response.data.message,
                });
              }
            } catch (error) {
              console.error("Error while adding serves:", error);
              Swal.fire({
                icon: "error",
                title: "Error",
                text:
                  error.response?.data?.message || "An unexpected error occurred.",
              });
            }
          },
     
    },
})