import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useEditModeratorsStore = defineStore("editModerators", {
    state: () => ({
        moderators: {},
    }),

    actions: {
        async fetchModeratorsDetails(id) {
            try {
                const response = await axios.post("fetch_employee_details", { id })
                if (response.data.status == true) {
                    this.moderators = response.data.data;
                } else {
                    console.log("Error fetching moderators.");
                }
            } catch (error) {
                console.log(error);
            }
        },
        async updateModerator(data, id) {
            try {
                const formData = new FormData();

                // Log the formData for debugging
                console.log('Form Data:', data);

                // Add the ID to the formData
                formData.append("id", id);

                // Append other form data to the FormData object
                Object.keys(data).forEach((key) => {
                    if (
                        Array.isArray(data[key]) &&
                        key !== "certificateImagesSrc" &&
                        key !== "images"
                    ) {
                        data[key].forEach((item) => {
                            formData.append(`${key}[]`, item);
                        });
                    } else if (
                        key !== "imageSrc" &&
                        key !== "images"
                    ) {
                        if (key === "image") {
                            if (data[key] instanceof File) {
                                formData.append("image", data[key]);
                            }
                        } else {
                            formData.append(key, data[key]);
                        }
                    }
                });

                // Log the form data before sending it
                console.log('Sending FormData:', formData);

                const response = await axios.post("edit_employee", formData, {
                    headers: { "Content-Type": "multipart/form-data" },
                });

                if (response.data.status === true) {
                    Swal.fire({
                        icon: "success",
                        title: "تم الحفظ بنجاح",
                        text: response.data.message || "تم الإضافه  بنجاح",
                      });
                    router.push("/index-moderators");
                    return response.data;
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "خطأ",
                        text: response.data.message,
                      });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: response.data.message,
                  });
                console.error("Error editing moderators:", error);
            }
        }

    }
})