<template>
  <div class="employees-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages :showButton="false" title="اضافة موظف" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">صوره</label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.form.imageSrc.$error">
              {{ getErrorMessage(v$.form.imageSrc) }}
            </span> -->
          
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label>صور الشهادات</label>
          <input
            ref="certificatesInput"
            accept="image/*"
            multiple
            style="display: none"
            type="file"
            @change="handleCertificatesChange"
          />
          <div class="upload-icon" @click="triggerCertificatesInput">
            <i class="fa fa-file-image-o"></i>
            <span>اختيار صور</span>
          </div>
          <div class="certificates-preview">
            <div
              v-for="(src, index) in form.certificateImagesSrc"
              :key="index"
              class="certificate-image"
            >
              <img :src="src" alt="Certificate Preview" />
              <i
                class="fa fa-times delete-icon"
                @click="removeCertificate(index)"
              ></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الموظف</label>
          <div class="input">
            <input
              id="name"
              v-model="form.name"
              placeholder="أدخل أسم الموظف"
              type="text"
            />
            <span class="error-feedback" v-if="v$.form.name.$error">
              {{ getErrorMessage(v$.form.name) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="identitynumber">
            <label for=""> ادخل الرقم الهويه</label>
            <div class="input">
              <input
                v-model="form.identity_number"
                placeholder="ادخل الرقم الهويه"
                type="number"
              />
            </div>
            <span class="error-feedback" v-if="v$.form.identity_number.$error">
              {{ getErrorMessage(v$.form.identity_number) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ الميلاد </label>
          <div class="input">
            <input
              v-model="form.date_of_birth"
              :max="new Date().toISOString().split('T')[0]"
              placeholder="ادخل تاريخ الميلاد"
              type="date"
            />
            <span class="error-feedback" v-if="v$.form.date_of_birth.$error">
              {{ getErrorMessage(v$.form.date_of_birth) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="phone">رقم الهاتف</label>
          <div class="input">
            <input
              id="phone"
              v-model="form.phone"
              @input="validateInput"
              class="no-spinner"
              placeholder="أدخل رقم الهاتف"
              type="tel"
            />
            <span class="error-feedback" v-if="v$.form.phone.$error">
              {{ getErrorMessage(v$.form.phone) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="email">البريد الالكتروني</label>
          <div class="input">
            <input
              id="email"
              v-model="form.email"
              placeholder="أدخل البريد الالكتروني"
              type="email"
            />
            <span class="error-feedback" v-if="v$.form.email.$error">
              {{ getErrorMessage(v$.form.email) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="password">الرقم السري</label>
          <div class="input" id="pass">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="password"
              v-model="form.password"
              placeholder="أدخل الرقم السري"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i v-if="showPassword" class="fa fa-eye"></i>
              <i v-else class="fa fa-eye-slash"></i>
            </span>
            <span class="error-feedback" v-if="v$.form.password.$error">
              {{ getErrorMessage(v$.form.password) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="age"> العمر </label>
          <div class="input">
            <input
              id="age"
              v-model="form.age"
              placeholder="أدخل العمر"
              type="number"
            />
            <span class="error-feedback" v-if="v$.form.age.$error">
              {{ getErrorMessage(v$.form.age) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">اختار الوظيفه</label>
          <multiselect
            v-model="jobType"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            :options="postionOptions"
            label="title"
            select-label=""
            deselect-label=""
            track-by="id"
            placeholder="اختار الوظيفه"
            @update:modelValue="updatePostion"
          ></multiselect>
          <span class="error-feedback" v-if="v$.jobType.$error">
            {{ getErrorMessage(v$.jobType) }}
          </span>
        </div>
        <div class="radio">
          <div class="col-lg-4 col-md-12 col-12">
            <label for="name">نوع الهوية</label>
            <div class="radio">
              <div class="form-check">
                <input
                  id="flexRadioDefault5"
                  v-model="form.identity_type"
                  class="form-check-input"
                  name="card"
                  type="radio"
                  value="0"
                />
                <label class="form-check-label" for="flexRadioDefault5">
                  بطاقة
                </label>
              </div>
              <div class="form-check">
                <input
                  id="flexRadioDefault6"
                  v-model="form.identity_type"
                  class="form-check-input"
                  name="stay"
                  type="radio"
                  value="1"
                />
                <label class="form-check-label" for="flexRadioDefault6">
                  اقامه
                </label>
              </div>
              <span class="error-feedback" v-if="v$.form.identity_type.$error">
                {{ getErrorMessage(v$.form.identity_type) }}
              </span>
            </div>
          </div>

          <div class="col-lg-4 col-md-12 col-12">
            <label for="name">الحاله الأجتماعيه</label>
            <div class="radio">
              <div class="form-check">
                <input
                  id="flexRadioDefault3"
                  v-model="form.marital_status"
                  class="form-check-input"
                  name="marrid"
                  type="radio"
                  value="1"
                />
                <label class="form-check-label" for="flexRadioDefault3">
                  متزوج
                </label>
              </div>
              <div class="form-check">
                <input
                  id="flexRadioDefault4"
                  v-model="form.marital_status"
                  class="form-check-input"
                  name="notmarrid"
                  type="radio"
                  value="0"
                />
                <label class="form-check-label" for="flexRadioDefault4">
                  اعزب
                </label>
              </div>
            </div>
            <span class="error-feedback" v-if="v$.form.marital_status.$error">
              {{ getErrorMessage(v$.form.marital_status) }}
            </span>
          </div>
          <div class="col-lg-4 col-md-12 col-12">
            <label for="gender">النوع</label>
            <div class="radio">
              <div class="form-check">
                <input
                  id="flexRadioDefault1"
                  v-model="form.gender"
                  class="form-check-input"
                  name="flexRadioDefault"
                  type="radio"
                  value="1"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  ذكر
                </label>
              </div>
              <div class="form-check">
                <input
                  id="flexRadioDefault2"
                  v-model="form.gender"
                  class="form-check-input"
                  name="flexRadioDefault"
                  type="radio"
                  value="2"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  أنثي
                </label>
              </div>
              <span class="error-feedback" v-if="v$.form.gender.$error">
                {{ getErrorMessage(v$.form.gender) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useEmployeesAddStore } from "@/stores/employees/EmployeesAddStore";
import { useVuelidate } from "@vuelidate/core";
import { email, required } from "@vuelidate/validators";
import { mapActions, mapState } from "pinia";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      showPassword: false,
      store: useEmployeesAddStore(),
      v$: useVuelidate(),
      form: {
        identity_type: 0,
        marital_status: 1,
        identity_number: "",
        date_of_birth: "",
        age: "",
        gender: 1,
        name: "",
        phone: "",
        email: "",
        password: "",
        image: null,
        imageSrc: "",
        is_employed: 0,
        certificate_images: [],
        certificateImagesSrc: [],
        job_type_id: null,
      },
      jobType: null,
      postionOptions: [],
    };
  },

  validations() {
    return {
      form: {
        identity_number: { required },
        // imageSrc: { required },
        name: { required },
        phone: { required },
        email: { required, email },
        password: { required },
        age: { required },
        gender: { required },
        date_of_birth: { required },
        marital_status: { required },
        identity_type: { required },
        // identity_type: { required },
        // identity_number: { required },
        // date_of_birth: { required },
        // job_type_id: { required },
      },
      jobType: { required },
    };
  },
  computed: {
    ...mapState(useEmployeesAddStore, {
      postions: (state) => state.postion,
    }),
  },
  methods: {
    validateInput() {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    ...mapActions(useEmployeesAddStore, [["addEmployee"]]),
    updatePostion() {
      console.log(this.jobType.id);
      this.form.job_type_id = this.jobType.id;
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    triggerCertificatesInput() {
      this.$refs.certificatesInput.click();
    },

    handleCertificatesChange(event) {
      const files = Array.from(event.target.files);
      this.form.certificate_images.push(...files);

      files.forEach((file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.certificateImagesSrc.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },

    removeCertificate(index) {
      this.form.certificate_images.splice(index, 1);
      this.form.certificateImagesSrc.splice(index, 1);
    },

    async submitForm() {
      // console.dir(this.form + "form");

      try {
        this.v$.$validate();
        console.log(this.v$);
        if (this.v$.$error) return; // تأكد من وجود أخطاء قبل المتابعة
        await this.addEmployee(this.form);
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    async fetchPostion() {
      await this.store.fetchPositions();

      this.postionOptions = this.store.postion;
    },
  },
  mounted() {
    this.fetchPostion();
  },
};
</script>

<style scoped lang="scss">
.radio {
  margin-right: 5px;
}
#pass {
  display: flex;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.11);
  background-color: #e8f0fe;
  border-radius: 15px;
  margin-top: 0.3rem;
  input {
    border: 0 !important;
    padding: 0.75rem !important;
    margin-top: 0;
  }
}
</style>

