<template>
  <div class="moderators">
    <header-pages
      title="المشرفين"
      button="+ اضافة مشرف جديد"
      link="/add-moderators"
      icon="fa-solid fa-triangle-exclamation"
    ></header-pages>
    <div class="alll">
      <div class="row">
        <div
          v-for="teacher in this.metadors"
          :key="teacher.id"
          class="col-lg-6 col-md-6 col-12"
        >
          <div class="cards_moderators">
            <div class="card_moderators">
              <div class="user-one">
                <div class="img-contant">
                  <div class="img">
                    <img
                      :src="
                        teacher.image || '@/assets/photos/Rectangle 8917.png'
                      "
                      alt="user"
                    />
                  </div>
                  <div class="contant-user">
                    <h6>{{ teacher.name }}</h6>
                    <p>{{ teacher.group }}</p>
                    <p><i class="fa-solid fa-phone"></i> {{ teacher.phone }}</p>
                    <p>
                      <i class="fa-solid fa-envelope"></i> {{ teacher.email }}
                    </p>
                  </div>
                </div>
                <div class="dropdown">
                  <button
                    class="btn btn-light dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="fa-solid fa-ellipsis-vertical"></i>
                  </button>
                  <ul
                    class="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a
                        class="dropdown-item"
                        :href="`/edit-moderators/${teacher.id}`"
                      >
                        تعديل بيانات
                      </a>
                    </li>
                    <li>
                      <a
                        class="dropdown-item"
                        @click="deleteTeacher(teacher.id)"
                      >
                        حذف
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useMetadorsStore } from "@/stores/medators/medatorsStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";

export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    PaginationPage,
  },
 
  computed: {
    ...mapState(useMetadorsStore, {
      metadors: (state) => state.metadors,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handlePageChange(page) {
      const moderetorsStore = useMetadorsStore();
      moderetorsStore.fetchMetadors(page);
    },
    // async fetchMetadors(page = 1) {
    //   const store = useMetadorsStore();
    //   await store.fetchMetadors(page); 
    //   this.Teacher = store.Teacher; 
    // },
    async deleteTeacher(id) {
      const store = useMetadorsStore();
      try {
        // استدعاء الدالة لحذف المشرف
        await store.deleteTeacher(id);

        // تحديث البيانات بعد الحذف
        this.Teacher = this.metadors.filter((teacher) => teacher.id !== id);
      } catch (error) {
        console.error("Error deleting teacher:", error);
      }
    },
  },
  async mounted() {
    const moderetorsStore = useMetadorsStore();
    await moderetorsStore.fetchMetadors();
  },
};
</script>
