<template>

    <div >
      <div class="headers-add">
        <i class="fa-solid fa-plus add"></i>
    <header-pages title="اضافة صله قرابه" :showButton="false"  />
    </div>
    <form @submit.prevent="submitForm">
  <div class="row">


  <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الأسم </label>
          <div class="input">
            <input
              v-model="relations.title"
              placeholder="أدخل الأسم"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.relations.title.$error">
            {{ getErrorMessage(v$.relations.title) }}
          </span>
        </div>
    </div>
    
    <div class="all-btn mt-5">
        <button type="submit" class="save" @click="Save()">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
</div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import {useRelationsAddStore} from "@/stores/Relations/RelationsAddStore";
import { mapState } from "pinia";

export default {
data() {
  return {
    v$: useVuelidate(),
   relations :{
    title : ""
   }
  };
},
validations() {
    return {
        relations: {
            title: { required },
      },
    };
},
computed: {
    ...mapState(useRelationsAddStore, {
        relations: (state) => state.relations,
    }),
  },
components: {
  HeaderPages,
},
methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },

    async submitForm() {
        this.v$.$validate();
      try {
        const relationStore = useRelationsAddStore();
        if (!relationStore) {
          // throw new Error("Failed to load relation store");
        }
        if (!this.relations.title) {
          // Swal.fire("Error", "Please fill in all fields", "error");
          return;
        }
        await relationStore.AddsRelations(this.relations);
        this.$router.push("/relation-index");
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    Save() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
},
}
</script>

<style scoped lang="scss">
.save{
  width: 20%;
}
</style>