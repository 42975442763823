<template>
  <div class="edit-moderators">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
      <HeaderPages title="تعديل مشرف جديد" :showButton="false" />
    </div>
    <form @submit.prevent="updateModeratos">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافه صوره </label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم المشرف </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder=" اكتب اسم المشرف كاملا "
              v-model="form.name"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> رقم الجوال</label>
          <div class="input">
            <input
              type="tel"
               @input="validateInput"
              id="name"
              placeholder="+966"
              v-model="form.phone"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> البريد الالكتروني </label>
          <div class="input">
            <input
              type="email"
              id="name"
              placeholder="ادخل البريد الالكتروني"
              v-model="form.email"
            />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> كلمة المرور </label>
          <div class="input" id="pass">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="name"
              placeholder=" ********"
              v-model="form.password"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i v-if="showPassword" class="fa fa-eye"></i>
              <i v-else class="fa fa-eye-slash"></i>
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> تأكيد كلمة المرور </label>
          <div class="input" id="pass">
            <input
              :type="showPassword ? 'text' : 'password'"
              id="name"
              placeholder=" ********"
              v-model="form.Confirm_password"
            />
            <span @click="togglePasswordVisibility" class="toggle-password">
              <i v-if="showPassword" class="fa fa-eye"></i>
              <i v-else class="fa fa-eye-slash"></i>
            </span>
          </div>
        </div>

        <!-- <div class="col-lg-6 col-md-6 col-sm-12" id="seleccct">
          <label for=""> المجموعه </label>
          <multiselect
            v-model="form.group"
            :options="postionOptions"
            :close-on-select="false"
            track-by="id"
            @update:modelValue="updatePostion"
          ></multiselect>
        </div> -->
        <div class="row">
          <div class="col-lg-4 col-md-6 col-12">
            <div class="checkbox">
              <form>
                <input
                  class="checkbox-input"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1">يمكن للمشرف اضافة تقييم للطالب</label
                ><br />
              </form>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="checkbox">
              <form>
                <input
                  class="checkbox-input"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1">يمكن للمشرف إضافة واجبات و اختبارات</label
                ><br />
              </form>
            </div>
          </div>
          <div class="col-lg-4 col-md-6 col-12">
            <div class="checkbox">
              <form>
                <input
                  class="checkbox-input"
                  type="checkbox"
                  id="vehicle1"
                  name="vehicle1"
                  value="Bike"
                />
                <label for="vehicle1"
                  >يمكن للمشرف التواصل مع ولى امر الطالب</label
                ><br />
              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useEditModeratorsStore } from "@/stores/medators/editModeratorsStore";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  // computed: {
  //   ...mapState(useEditModeratorsStore, {
  //     moderators: (state) => state.moderators,
  //   }),
  // },
  data() {
    return {
      showPassword: false,
      store: useEditModeratorsStore(),
      form: {
        is_employed: 0,
        email: "",
        image: "",
        name: "",
        phone: "",
        password: "",
        Confirm_password: "",
        imageSrc: "",
      },
      postionOptions: ["معلم", "موظف"],
      CurriculaOptions: [],
    };
  },

  methods: {
    validateInput() {
      this.form.phone = this.form.phone.replace(/[^0-9]/g, "");
    },
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    // add img 1
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async fetchDetails() {
  const id = this.$route.params.id;
  await this.store.fetchModeratorsDetails(id);
  if (this.store.moderators) {
    this.form = { ...this.store.moderators }; 
    if (this.store.moderators.image) {
      this.form.imageSrc = this.store.moderators.image;
    }
  }
},


async updateModeratos() {
  const store = useEditModeratorsStore();
  const id = this.$route.params.id;
  console.log('Moderator ID:', id);

  if (!id) {
    console.error('Invalid ID');
    return;
  }
  await store.updateModerator(this.form, id);
},

    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    // add img 1
  },
  mounted() {
    this.fetchDetails();
  },
};
</script>

<style scoped lang="scss">
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}
#pass {
  display: flex;
  align-items: center;
 border: 1px solid rgba(0, 0, 0, 0.11);
  background-color: #e8f0fe;
  border-radius: 15px;
  margin-top: 0.3rem;
  input {
    border: 0 !important;
    padding: 0.75rem !important;
    margin-top: 0;
  }
}
.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
.checkbox-input {
  width: 5%;
}
</style>
