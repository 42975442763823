import {defineStore} from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useHeaderAddStore = defineStore("headerAdd", {
    state: () => ({
        header: [],
    }),

    actions: {
        async addHeader(HeaderData) {
            try {
                const formData = new FormData();
                Object.keys(HeaderData).forEach((key) => {
                    if (Array.isArray(HeaderData[key])) {
                        HeaderData[key].forEach((item) => {
                            formData.append(`${key}[]`, item);
                        });
                    } else {
                        formData.append(key, HeaderData[key]);
                    }
                });

                const response = await axios.post("organization_add_header", formData, {
                    header: {"Content-Type": "multipart/form-data"},
                });

                if (response.data.status) {
                    this.header.push(response.data.data);
                    Swal.fire({
                        icon: "success",
                        title: "تم الحفظ بنجاح",
                        text: response.data.message || "تم إضافة الهيدر بنجاح",
                      });
                    await router.push("/index-header");

                } else {
                    Swal.fire({
                        icon: "error",
                        title: "Error",
                        text: response.data.message,
                    });
                }
            } catch (error) {
                console.error("Error while adding header:", error);
                Swal.fire({
                    icon: "error",
                    title: "Error",
                    text: error.response.data.message,
                });
            }
        },
    }

})