import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useLoadingStore } from "@/stores/loaderpage/LoadingStore";

export const useAllServicesStore = defineStore("servicesStore", {
  state: () => ({
    FeatueServs: [],
  }),
  actions: {
    async fetchFeatureServicesStore(page = 1, word = "") {
      const loadingStore = useLoadingStore();
      loadingStore.startLoading();

      try {
        const response = await axios.post(
          `organization_fetch_service_features?page=${page}`,
          {
            word: word,
          }
        );
        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;

        if (response.data.status == true) {
          this.FeatueServs = response.data.data.data;
          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
        } else {
          console.error("Failed to fetch services:", response.data.message);
        }
      } catch (error) {
        console.error("Error fetching services:", error);
        Swal.fire("Error", "Unable to fetch services", "error");
      } finally {
        loadingStore.stopLoading();
      }
    },

    async deleteServicesStore(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("organization_delete_service_feature", { id });
          this.FeatueServs = this.FeatueServs.filter(
            (service) => service.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف المكتبه", "success");
        }
      } catch (error) {
        console.error("Error deleting service:", error);
        Swal.fire("Error", "There was an error deleting the service.", "error");
      }
    },
  },
});
