<script>
import { mapActions, mapState } from "pinia";
import { useExamResultShow } from "@/stores/ExamResult/ExamResultShow";

export default {
  name: "ExamResultShow",
  components: {},
  computed: {
    ...mapState(useExamResultShow, ["examResult"]),
  },
  methods: {
    ...mapActions(useExamResultShow, ["getExamResult"]),
  },
  mounted() {
    this.getExamResult(this.$route.params.id);
  },
};
</script>

<template>
  <div class="alll">
    <section class="result-exam mx-auto p-4 bg-gray-100 rounded-xl px-4">
      <h4 class="nmes">نتيجة الاأختبار</h4>
      <div class="flex flex-col">
        <h4>{{ examResult?.exam?.name }}</h4>
        <div class="row statistics gap-4 mt-4 bg-gray-100">
          <span class="col-3">
            <i class="fa fa-clock"></i>
            المدة الاختبار:
            {{ examResult?.exam?.duration }}
          </span>
          <span class="col-3">
            <i class="fa fa-clipboard"></i>
            عدد الاسئلة: {{ examResult?.exam?.question_count }} </span
          ><span class="col-3">
            <i class="fa fa-clipboard"></i>
            عدد الاسئلة التي تم الاجابة عليها :
            {{ examResult?.questions?.length }} </span
          ><span class="col-3">
            <i class="fa fa-clipboard"></i>
            الدرجة :
            {{ examResult?.exam?.total_degree }}
          </span>

          <span class="col-3">
            <i class="fa fa-calendar"></i>
            تاريخ الاختبار: {{ examResult?.exam?.start_date }}
          </span>
          <span class="col-3">
            <i class="fa fa-calendar"></i>
            تاريخ الاختبار: {{ examResult?.exam?.start_date }}
          </span>
        </div>
        <div>
          <div class="mt-4 results">
            <h4 class="text-2xl font-bold text-gray-600">النتائج</h4>
            <div class="d-flex flex-column gap-4 mt-4">
              <div
                v-for="question in examResult?.questions"
                :key="question.id"
                class="p-4 result-card"
              >
                <p class="text-lg font-semibold">
                  {{ question?.question }}
                </p>
                <div class="d-flex align-items-start">
                  <span>اجابتك:</span>
                  <span
                    :class="[
                      'flex-1',
                      'mx-2',
                      question?.answer?.is_correct
                        ? 'text-success'
                        : 'text-danger',
                    ]"
                  >
                    {{ question?.answer?.answer }}
                  </span>
                </div>
                <div
                  class="d-flex align-items-start"
                  v-if="!question?.answer?.is_correct"
                >
                  <span>الاجابة الصحيحة:</span>
                  <span class="flex-1 mx-2">
                    {{
                      question?.correct_answer?.answer ??
                      question?.correct_answer
                    }}
                  </span>
                </div>
                <div class="mt-4">
                  <p class="text-lg font-semibold">
                    الدرجة: {{ question?.degree }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<style scoped>
.alll {
  border: 0 !important;
}
.statistics {
  transition: 0.7s;
  border-radius: 15px !important;
}
.result-card {
  transition: 0.7s;
  border-radius: 15px !important;
}
.statistics:hover {
  box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.116);
}
.result-card:hover {
  box-shadow: 1px 2px 20px 11px rgba(0, 0, 0, 0.116);
}
h4.nmes {
  color: var(--main);
  margin-bottom: 1rem;
}
</style>
