import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useJobsStore = defineStore("jobs", {
  state: () => ({
    jobs: [], 
  }),
  actions: {
    async getJobs() {
      try {
        const response = await axios.post("fetch_job_types");
        console.log(response.data , "ddddddddddddddddd");
        
        if (response.data.status === true) {
          this.jobs = response.data.data.data;
        } else {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الوظيفه بنجاح",
          });
          router.push("/index-jobs"); 
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error?.response?.data?.message || "حدث خطأ غير متوقع",
        });
      }
    },
    async deleteJobs(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_job_type", { id });
          this.jobs = this.jobs.filter((job) => job.id !== id);
          Swal.fire("تم الحذف!", "تم حذف الوظيفه", "success");
        }
      } catch (error) {
        console.error("Error deleting Jobs:", error);
        Swal.fire("Error!", "There was an error deleting the Job.", "error");
      }
    },
  },
});
