import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";


export const useHeaderEditStore = defineStore("headerEdit", {
    state: () => ({
        header: {},
    }),

    actions: {
        async getHeader(id) {
            try {
                const response = await axios.post("organization_fetch_header_details", { id });
                if (response.data.status) {
                    this.header = response.data.data;
                } else {
                    console.error("Error fetching headers:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }
        },

        async updateHeader(formData) {
            try {
                const response = await axios.post("organization_edit_header", formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status) {
                    this.header = response.data.data;
                    Swal.fire({
                        icon: "success",
                        title: "تم التعديل بنجاح",
                        text: response.data.message || "تم تعديل الهيدر بنجاح",
                    }); 
                    await router.push("/index-header");
                } else {
                    console.error("Error updating header:", response.data.message);
                }
            } catch (error) {
                console.error("Error", "Failed to update header.", "error");
                Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: response.data.message,
                  });


            }
        },
    }
});
