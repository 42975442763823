import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useTeacherAddStore = defineStore("teacherAdd", {
  state: () => ({
    teachers: [],
    curriculums: [],
    jobTypes: [],
  }),
  actions: {
    async getCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        this.curriculums = response.data.data;
      } catch (error) {
        console.error("Error fetching curriculums:", error);
      }
    },

    async getJobTypes() {
      try {
        const response = await axios.post("fetch_job_types");
        this.jobTypes = response.data.data.data;
        console.log(this.jobTypes, "jobTypes");
      } catch (error) {
        console.error("Error fetching job types:", error);
      }
    },

    async addTeacher(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key]) && key !== "certificateImagesSrc") {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (key !== "imageSrc" && key !== "certificateImagesSrc") {
            formData.append(key, data[key]);
          }
        });

        const response = await axios.post("add_employee", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة المعلم بنجاح",
          });
          router.push("teacher-index");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
        console.error("Error adding teacher:", error);
      }
    },
  },
});
