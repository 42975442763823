import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";

export const uselibraryeditStore = defineStore("libraryedit", {
  state: () => {
    return {
      categorys: [],
    };
  },
  actions: {
    async getlibrary(id) {
      try {
        const response = await axios.post("fetch_library_details", {
          id: id,
        });
        this.library = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async editlibrary(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            // Handle arrays (if needed in the future)
          } else {
            // Skip imageSrc to avoid sending it
            if (key === "imageSrc") return;

            // Only append the file if it's an instance of File
            if (key === "file") {
              if (key instanceof File) {
                formData.append(key, data[key]);
              }
            } else {
              // Append other fields normally
              formData.append(key, data[key]);
            }
          }
        });

        // Send the FormData to the API
        const response = await axios.post("edit_library", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل المكتبه بنجاح",
          });
          router.push("/index-electroniclibrary");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error editing library:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: response.data.message,
        });
      }
    },
    async fetchCategory() {
      try {
        const response = await axios.post("fetch_library_categories");
        if (response.data.status) {
          this.categorys = response.data.data.data;
          console.log(this.categorys);
        } else {
          throw new Error("Failed to fetch categorys data");
        }
      } catch (error) {
        console.error(error);
      }
    },
  },
});
