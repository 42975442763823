import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useSubHeadersAddStore = defineStore("subHeadersAdd", {
  state: () => ({
    subHeaders: [],
  }),
  actions: {
    async addSubHeader(data) {
      try {
        const formData = new FormData();

        formData.append("title", data.title);
        formData.append("subtitle", data.subtitle);
        formData.append("description", data.description);

        if (data.image) {
          formData.append("image", data.image);
        }

        data.features.forEach((feature, index) => {
          if (feature.file) {
            formData.append(`features[${index}][image]`, feature.file);
            formData.append(`features[${index}][title]`, feature.title);
            formData.append(
              `features[${index}][description]`,
              feature.description
            );
          }
        });

        const response = await axios.post("organization_add_subheader", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة العنوان بنجاح",
          });
          router.push("/whowe");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message || "حدث خطأ",
          });
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message || "حدث خطأ أثناء إرسال البيانات.",
        });
      }
    },
  },
});
