import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";

export const useSuccesspartnersEditStore = defineStore("successpartnersEdit", {
    state: () => ({
        successpartners: [],
    }),
    actions: {
        async getSuccesspartners(id) {
            try {
                const response = await axios.post("fetch_partner_details", {id});

                if (response.data.status === true) {
                    this.successpartners = response.data.data;
                } else {
                    console.error("Error fetching partner:", response.data.message);
                }
            } catch (error) {
                console.error("Error fetching partner:", error);
                Swal.fire("خطأ!", "حدث خطأ أثناء جلب تفاصيل الشريك.", "error");
            }
        },
        async updateSuccesspartners(formData) {
            try {
                const response = await axios.post("edit_partner", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                });

                if (response.data.status === true) {
                    this.successpartners = response.data.data;
                    Swal.fire({
                        icon: "success",
                        title: "تم التعديل بنجاح",
                        text: response.data.message || "تم تعديل الشريك بنجاح",
                      });
                    console.log("ssssss", this.successpartners);
                } else {
                    console.error("Error updating partner:", response.data.message);
                    Swal.fire({
                        icon: "error",
                        title: "خطأ",
                        text: response.data.message,
                      });                }
            } catch (error) {
                console.error("Error", "Failed to update header.", "error");
                Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: response.data.message,
                  });
            }
        },
    },
});
