import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";
import { isArray } from "chart.js/helpers";

export const useCompetitionAddStore = defineStore("competitionAdd", {
  state: () => ({
    token: localStorage.getItem("token") || null,
    data: {},
  }),

  actions: {
    async addCompetition(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (key === "image") {
            if (data[key] instanceof File) {
              formData.append("image", data[key]);
            }
          } else if (isArray(data[key])) {
            data[key].forEach((item, index) => {
              Object.keys(item).forEach((subKey) => {
                formData.append(`${key}[${index}][${subKey}]`, item[subKey]);
              });
            });
          } else {
            formData.append(key, data[key]);
          }
        });
        const response = await axios.post("add_competition", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
        this.data = response.data;
        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة  المسابقه بنجاح",
          });
          router.push("/index-competion");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: error.response.data.message,
          });
     
        }
      } catch (error) {
        console.log(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
  },
});
