import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useTeacherEditStore = defineStore("teacherEdit", {
  state: () => ({
    curriculums: [],
    jobTypes: [],
    teacher: null,
    images: [],
    job_type: null,
  }),

  actions: {
    async getCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        this.curriculums = response.data.data;
      } catch (error) {
        console.error("Error fetching curriculums:", error);
      }
    },

    async getJobTypes() {
      try {
        const response = await axios.post("fetch_job_types");
        this.jobTypes = response.data.data.data;
      } catch (error) {
        console.error("Error fetching job types:", error);
      }
    },

    async getTeacher(id) {
      try {
        const response = await axios.post("fetch_employee_details", { id });

        if (response.data.status === true) {
          this.teacher = response.data.data;
          this.job_type = this.teacher.job_type;
          this.images = this.teacher.images.map((im) => ({
            image: im.image,
            id: im.id,
          }));
        } else {
          throw new Error("Failed to fetch teacher data");
        }
      } catch (error) {
        console.error("Error fetching teacher:", error);
      }
    },

    async EditTeacher(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (
            Array.isArray(data[key]) &&
            key !== "certificateImagesSrc" &&
            key !== "curriculums" &&
            key !== "images"
          ) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (
            key !== "imageSrc" &&
            key !== "certificateImagesSrc" &&
            key !== "curriculums" &&
            key !== "images" &&
            key !== "job_type"
          ) {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        });

        // Send FormData to the API
        const response = await axios.post("edit_employee", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل المعلم بنجاح",
          });
          router.push("/teacher-index");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: response.data.message,
        });      }
    },
  },
});
