<template>
    <div class="jobs">
      <header-pages
        title="الوظائف"
        button="+ اضافة وظيفة جديدة"
        link="/add-jops"
        icon="fa-solid fa-rotate-left"
      />
  
      <div class="alll">
        <tables-page-vue
          :headers="tableHeaders"
          :rows="tableRows"
          :pages="tablePages"
          :showSelect="false"
          :editLink="editLink"
          :viewLink="viewLink"
          @delete="handleDeleteJops"
        />

        <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
      </div>
    </div>
  </template>
  
  <script>
  import HeaderPages from "@/components/headerpages/HeaderPages.vue";
  import TablesPageVue from "@/components/tables/TablesPage.vue";
  import { useJobsStore } from "@/stores/jopsType/JopsStore";
  import { mapState } from "pinia";
  import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
  
  export default {
    components: {
      HeaderPages,
      TablesPageVue,
      PaginationPage
    },
  
    data() {
      return {
        tableHeaders: ["ID", "الوظيفه"], 
        editLink: "/edit-jops", 
        viewLink: "/details", 

      };
    },
  computed:{
    ...mapState(useJobsStore, {
        jobs: (state) => state.jobs,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
  console.log(this.jobs);
  if (!Array.isArray(this.jobs)) {
    console.warn("jobs is not an array:", this.jobs);
    return [];
  }
  return this.jobs.map((jb) => [jb.id, jb.title]); 
},

    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
    async mounted() {
      const jopsStore = useJobsStore();
      jopsStore.getJobs(); 
    },
  
    methods: {
        
    handlePageChange(page) {
      const jopsStore = useJobsStore();
      jopsStore.getJobs(page);
    },
      handleDeleteJops(id) {
        
      const jopsStore = useJobsStore();
      jopsStore.deleteJobs(id);
      },
    },
  };
  </script>
  
  <style scoped>
  
  </style>
  