<template>

    <div >
      <div class="heaader">
        <i class="fa-solid fa-pen-to-square edit"></i>
    <header-pages title="تعديل صله القرابه" :showButton="false"  />
    </div>
    <form @submit.prevent="updateRelations">
  <div class="row">


  <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الأسم </label>
          <div class="input">
            <input
              v-model="relations.title"
              placeholder="أدخل الأسم"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.relations.title.$error">
            {{ getErrorMessage(v$.relations.title) }}
          </span>
        </div>
    </div>
    
    <div class="all-btn mt-5">
        <button type="submit" class="save" @click="Edit()">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
</div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useRelationsEditStore } from "@/stores/Relations/RelationsEditStore";
import { mapState } from "pinia";
import Swal from "sweetalert2";
export default {
data() {
  return {
    v$: useVuelidate(),
    relations :{
    title : ""
   }
  };
},
validations() {
    return {
        relations: {
            title: { required },
      },
    };
},
computed: {
    ...mapState(useRelationsEditStore, {
        relations: (state) => state.relations,
    }),
  },
components: {
  HeaderPages,
},
methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    Edit() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
    async fetchEditData() {
      const store = useRelationsEditStore();
      const id = this.$route.params.id;
      await store.getRelations(id);
      this.relations = { ...store.relations };
     
    },
    async updateRelations() {
      this.v$.$validate();
      const store = useRelationsEditStore();
      const formData = new FormData();

      formData.append("id", this.relations.id);
      

        formData.append("title", this.relations.title);
      

      await store.updateRelations(formData);

    },
},

mounted() {
  this.fetchEditData();
},

};
</script>

<style scoped>
.save{
  width: 20%;
}
</style>