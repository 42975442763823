<template>
  <div class="employees">
    <HeadersPages
      title="المسابقات"
      button="+ اضافة مسابقة"
      link="/add-competion"
      icon="fa-solid fa-trophy"
    />
    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن مسابقة..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="handleDeleteExams"
        :ismaster="ismaster"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useCompetitionStore } from "@/stores/competition/CompetitionStore";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "EmployeesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "الصورة", "الاسم ", "الوصف"],
      editLink: "/edit-competion",
      viewLink: "/competition-view",
    };
  },
  computed: {
    ...mapState(useCompetitionStore, {
      competitions: (state) => state.competitions,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      const dataToDisplay = this.competitions;
      return dataToDisplay.map((exa) => [
        exa.id,
        exa.image,
        exa.name,
        exa.description,
        // exa.rewards
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const examsStore = useCompetitionStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await examsStore.fetchCompetitions(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await examsStore.fetchCompetitions(1, this.word);

      if (examsStore.competitions.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    
    handlePageChange(page) {
      const curriculaStore = useCompetitionStore();
      curriculaStore.fetchCompetitions(page);
    },
    async handleDeleteExams(id) {
      const examsStore = useCompetitionStore();
      await examsStore.deleteCompetitions(id);
    },
  },

  async mounted() {
    const examsStore = useCompetitionStore();
    await examsStore.fetchCompetitions();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped>

</style>
