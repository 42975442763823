<template>
  <div class="jops-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <header-pages title="اضافة وظيفه" :showButton="false" />
    </div>
    <form action="" @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الوظيفه</label>
          <div class="input">
            <input
              v-model="jobs.title"
              placeholder="أدخل أسم الوظيفه"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.jobs.title.$error">
            {{ getErrorMessage(v$.jobs.title) }}
          </span>
        </div>
      </div>
      <div class="all-btn mt-4">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
  
  <script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { useJopsAddStore } from "@/stores/jopsType/JopsAddStore";
import { mapState } from "pinia";

export default {
  components: { HeaderPages },
  data() {
    return {
      v$: useVuelidate(),
      jobs: {
        title: "",
      },
    };
  },
  validations() {
    return {
      jobs: {
        title: { required },
      },
    };
  },
  computed: {
    ...mapState(useJopsAddStore, {
      jops: (state) => state.jops,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },


    async submitForm() {
      this.v$.$validate();
      if (!this.v$.$error) {
        try {
          const jopsStore = useJopsAddStore();
          if (!jopsStore) {
            return;
          }
          if (!this.jobs.title) {
            return;
          }
          await jopsStore.AddJobs(this.jobs);
          this.$router.push("/index-jops");
        } catch (error) {
          console.error("Error in submitForm:", error);
        }
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    Save() {
      this.v$.$validate();
      if (!this.v$.$error) {
        console.log("no error");
      }
    },
  },
};
</script>
  
  <style scoped>
button.save {
  width: 20%;
}
</style>