<template>
  <div class="add-successpartners">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <HeaderPages title="شركاء النجاح" :showButton="false" />
    </div>
    <form @submit.prevent="updateheader">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافة صورة</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
              <span class="error-feedback" v-if="v$.form.imageSrc.$error">{{
                getErrorMessage(v$.form.imageSrc)
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">عنوان الهيدر</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.title"
            />
            <span class="error-feedback" v-if="v$.form.title.$error">{{
              getErrorMessage(v$.form.title)
            }}</span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اللينك</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="form.link"
            />
            <span class="error-feedback" v-if="v$.form.link.$error">{{
              getErrorMessage(v$.form.link)
            }}</span>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useSuccesspartnersEditStore } from "@/stores/Successpartners/SuccesspartnersEditStore";
import { mapState } from "pinia";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import "vue-multiselect/dist/vue-multiselect.css";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        imageSrc: "",
        title: "",
        link: "",
      },
      CurriculaOptions: [],
    };
  },
  computed: {
    ...mapState(useSuccesspartnersEditStore, {
      successpartners: (state) => state.successpartners,
    }),
  },
  validations() {
    return {
      form: {
        imageSrc: { required },
        title: { required },
        link: { required },
      },
    };
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    async fetchEditData() {
      const store = useSuccesspartnersEditStore();
      const id = this.$route.params.id;
      await store.getSuccesspartners(id);
      this.form = { ...store.successpartners };
      this.form.imageSrc = store.successpartners.image;
    },
    async updateheader() {
      if (!this.form.imageSrc || !this.form.title || !this.form.link) {
        Swal.fire("Error", "Please fill in all fields", "error");
        return;
      }

      const store = useSuccesspartnersEditStore();
      const formData = new FormData();
      formData.append("id", this.form.id);
      formData.append("image", this.form.image);
      formData.append("title", this.form.title);
      formData.append("link", this.form.link);

      await store.updateSuccesspartners(formData);

      Swal.fire("تم التعديل بنجاح", "", "success");
      this.$router.go(-1);
    },
    // add img 1
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
    // add img 1
  },
  mounted() {
    this.fetchEditData();
  },
};
</script>
