<template>
  <div class="add-successpartners">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <header-pages title="إضافه أشتراك" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">الكورسات</label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            deselect-label=""
            v-model="course_idd"
            :options="courseOptions"
            track-by="id"
            label="title"
            select-label=""
            @update:modelValue="updateCourse"
          ></multiselect>
          <!-- <span class="error-feedback" v-if="v$.sub.course_idd.$error">{{
              getErrorMessage(v$.sub.course_idd)
            }}</span> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="inputs">
            <label for="">المجموعات</label>
            <multiselect
              :clear-on-select="true"
              :close-on-select="true"
              :hide-selected="true"
              select-label=""
              deselect-label=""
              v-model="group_idd"
              :options="groupsOptions"
              track-by="id"
              label="title"
              :multiple="false"
              @update:modelValue="updateGroup"
            />

            <!-- <span class="error-feedback" v-if="v$.sub.group_idd.$error">{{
              getErrorMessage(v$.sub.group_idd)
            }}</span> -->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="inputs">
            <label for="">الطلاب</label>
            <multiselect
              :clear-on-select="true"
              :close-on-select="true"
              :hide-selected="true"
              select-label=""
              deselect-label=""
              v-model="sub.user_ids"
              :options="studentOptions"
              track-by="id"
              label="title"
              :multiple="true"
              :hidden-select="true"
              @update:modelValue="updateStudent"
            />
            <!-- <span class="error-feedback" v-if="v$.sub.user_ids.$error">{{
              getErrorMessage(v$.sub.user_ids)
            }}</span> -->
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import { useSubscriptionAddStore } from "@/stores/subscription/subscriptionAddStore";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapState } from "pinia";
// import useVuelidate from "@vuelidate/core";
// import { required } from "@vuelidate/validators";
// import Swal from "sweetalert2";

export default {
  components: { HeaderPages, Multiselect },
  data() {
    return {
      // v$: useVuelidate(),
      sub: {
        course_id: "",
        group_id: "",
        user_ids: [],
      },

      groupsOptions: [],
      studentOptions: [],
      courseOptions: [],
      group_idd: "",
      user_ids: [],
      course_idd: "",

      store: useSubscriptionAddStore(),
    };
  },

  // validations() {
  //   return {
  //     sub: {
  //       course_id: { required },
  //       group_id: { required },
  //       user_ids: { required },
  //     },
  //   };
  // },
  computed: {
    ...mapState(useSubscriptionAddStore, {
      subscription: (state) => state.subscription,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    updateCourse() {
      this.sub.course_id = this.course_idd.id;
    },
    updateGroup() {
      this.sub.group_id = this.group_idd.id;
      console.log(this.sub.group_id, "mmmmmmmm");
    },
    // updateStudent() {
    //   this.sub.user_ids  = this.user_ids.id;
    // },

    async fetchCourses() {
      await this.store.fetchCourses();
      this.courseOptions = this.store.courses.map((course) => {
        return {
          id: course.id,
          title: course.name,
        };
      });
    },
    async fetchGroups() {
      await this.store.fetchGroups();
      this.groupsOptions = this.store.groups;
      // console.log(this.groups + "mmmmmmmm");
    },
    async fetchStudents() {
      await this.store.fetchStudents();
      this.studentOptions = this.store.students.map((student) => {
        return {
          id: student.id,
          title: student.name,
        };
      });
    },

    async submitForm() {
      // this.v$.$validate();
      // if (this.v$.$error) return;
      try {
        const subscriptionStore = useSubscriptionAddStore();
        if (!subscriptionStore) {
          throw new Error("Failed to load Courses store");
        }

        // Validate inputs
        if (!this.sub.course_id || !this.sub.group_id || !this.sub.user_ids) {
          // Swal.fire("Error", "Please fill in all fields", "error");
          return;
        }

        this.sub.user_ids = this.sub.user_ids.map((user) => user.id);
        await subscriptionStore.addSubscription(this.sub);
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    // Save() {
    //   this.v.$validate();
    //   if (!this.v.$error) {
    //     console.log("no error");
    //   }
    // },
  },
  mounted() {
    this.fetchCourses();
    this.fetchGroups();
    this.fetchStudents();
  },
};
</script>
