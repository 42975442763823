<template>
  <div class="add_teacher">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <HeaderPages title="إضافة مكتبه جديد" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">ملف</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept=".pdf,.doc,.docx,.ppt,.pptx"
              ref="fileInput"
              style="display: none"
            />

            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار ملف</span>
            </div>

            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>

            <!-- Show the file name or an indicator if a file is selected -->
            <div v-if="form.file" class="file-info">
              <span>تم تحديد الملف: {{ form.file }}</span>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-sm-12 my-2">
          <label for="">الاسم</label>

          <div class="input">
            <input type="text" placeholder="الاسم" v-model="form.name" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> الوصف </label>
          <div class="input">
            <textarea
              placeholder="الوصف"
              v-model="form.description"
              cols="30"
              rows="10"
              class="w-100 input"
            ></textarea>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> الاقسام </label>
          <div class="inputs">
            <multiselect
            :clear-on-select="true"
          placeholder="الاقسام"
              :hide-selected="true"
              select-label=""
              deselect-label=""
              v-model="form.library_category_id"
              :options="store.categorys"
              :close-on-select="false"
              :multiple="false"
              label="title"
              track-by="id"
            ></multiselect>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { uselibraryeditStore } from "@/stores/librarires/libraryStoreEdit";
import useVuelidate from "@vuelidate/core";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: uselibraryeditStore(),
      v$: useVuelidate(),
      form: {
        name: "",
        description: "",
        file: null,
        imageSrc: "",
        library_category_id: null,
      },
    };
  },

  methods: {
    removeImage() {
      this.form.file = null;
      this.form.imageSrc = "";
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        // Check if the file type is allowed
        const allowedTypes = [
          "application/pdf",
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          "application/vnd.ms-powerpoint",
          "application/vnd.openxmlformats-officedocument.presentationml.presentation",
        ];
        if (!allowedTypes.includes(file.type)) {
          alert(
            "Invalid file type. Only PDF, DOC, DOCX, PPT, and PPTX files are allowed."
          );
          return;
        }

        this.form.file = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },

    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.form.library_category_id = this.form.library_category_id.id;
      this.store.editlibrary(this.form);
    },
  },

  async mounted() {
    this.store.fetchCategory();
    await this.store.getlibrary(this.$route.params.id);

    console.log(this.store.library, "library");

    this.form = this.store.library;
    this.form.library_category_id = this.store.library.library_category;
    // this.form.file = this.store.library.file;
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
input#vehicle1 {
  width: 5%;
}
</style>
