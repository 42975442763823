import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useJopsAddStore = defineStore("jops", {
  state: () => ({
    jops: [],
  }),
  actions: {
    async AddJobs(Data) {
      try {
        const formData = new FormData();
        
        Object.keys(Data).forEach((key) => {
          if (Array.isArray(Data[key])) {
            Data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, Data[key]);
          }
        });

        const response = await axios.post("add_job_type", formData, {
          headers: {
            "Content-Type": "multipart/form-data", 
          },
        });

        if (response.data.status === true) {
          this.jops.push(response.data.data.data);
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الوظيفه بنجاح",
          });
          router.push("/index-jops");
        } else {
          Swal.fire({
            icon: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error while adding jobs:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: error.response?.data?.message || "An unexpected error occurred.",
        });
      }
    },
  },
});
