<template>
  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th v-for="(header, index) in headers" :key="index">
            {{ header }}
          </th>
          <th v-if="$route.path !== '/index-complaints'">actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in rows" :key="rowIndex">
          <td v-for="(cell, cellIndex) in row" :key="cellIndex">
            <span v-if="cell instanceof Object">
              <a v-if="cell.icon" :href="cell.to">
                <i class="fa-solid fa-file-pdf"></i>
              </a>
              <router-link :to="cell.to" v-else>{{ cell.to }} </router-link>
            </span>
            <div v-else-if="isLink(cell)" class="img">
              <img
                v-if="!isType(rowIndex)"
                :src="cell"
                alt="Image"
                class="image"
              />

              <button
                v-else
                :data-bs-target="'#videoModal' + rowIndex"
                class="btn"
                data-bs-toggle="modal"
                style="border: 1px solid var(--main)"
                type="button"
              >
                <i class="fa-solid fa-play"></i>
              </button>

              <!-- Modal for Video -->
              <div
                :id="'videoModal' + rowIndex"
                aria-hidden="true"
                aria-labelledby="videoModalLabel"
                class="modal fade"
                tabindex="-1"
              >
                <div class="modal-dialog modal-lg">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 id="videoModalLabel" class="modal-title">Video</h5>
                      <button
                        aria-label="Close"
                        class="btn-close"
                        data-bs-dismiss="modal"
                        type="button"
                      ></button>
                    </div>
                    <div class="modal-body">
                      <video :src="cell" class="w-100" controls></video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span v-else-if="Array.isArray(cell)">
              <span
                v-for="(item, index) in cell"
                :key="index"
                class="d-flex align-items-start flex-column"
              >
                - {{ item }}
              </span>
            </span>
            <span v-else>{{ cell === "" ? "--" : cell }}</span>
          </td>
          <td>
            <div class="action-icons">
              <i
                v-if="canDelete(rowIndex)"
                class="red fa-solid fa-trash action-icon"
                @click="handleIconClick('delete', row[0])"
              ></i>
              <i
                v-if="canEdit()"
                class="fa-solid fa-pen-to-square action-icon"
                @click="handleIconClick('edit', row[0])"
              ></i>
              <i
                v-if="canView()"
                class="fa-solid fa-eye action-icon"
                @click="handleIconClick('view', row[0])"
              ></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useWhowweStore } from "@/stores/subHeaders/WhoweStore";
import { isLink } from "@/helpers/isLink";
import { isArray } from "chart.js/helpers";
import router from "@/router";

export default {
  name: "TablesPage",
  components: {
    Multiselect,
    // pagination,
  },
  props: {
    currentPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    headers: {
      type: Array,
      required: true,
    },
    rows: {
      type: Array,
      required: true,
    },
    pages: {
      type: Array,
      default: () => [1, 2, 3],
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    editLink: {
      type: String,
    },
    viewLink: {
      type: String,
    },
    ismaster: {
      type: Array,
      default: () => [],
    },
    type: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      value: [],
      options: ["list", "of", "options"],
      store: useWhowweStore(),
    };
  },

  methods: {
    canDelete(rowIndex) {
      return (
        this.isMaster(rowIndex) &&
        this.$route.path !== "/index-complaints" &&
        this.$route.path !== "/exam-results"
      );
    },
    canEdit() {
      return (
        this.$route.path !== "/index-subscription" &&
        this.$route.path !== "/index-complaints" &&
        this.$route.path !== "/exam-results"
      );
    },
    canView() {
      return (
        this.$route.path === "/groups" ||
        this.$route.path === "/index-competion" ||
        this.$route.path === "/exam-results"
      );
    },
    router() {
      return router;
    },
    isArray,
    changePage(page) {
      if (page >= 1 && page <= this.totalPages) {
        this.$emit("page-changed", page);
      }
    },

    handleIconClick(action, id) {
      if (action === "view") {
        this.$router.push(this.viewLink + "/" + id);
      } else if (action === "edit") {
        this.$router.push(this.editLink + "/" + id);
      } else if (action === "delete") {
        this.$emit("delete", id);
      }
    },

    isMaster(index) {
      if (this.ismaster[index] === undefined) {
        return true;
      }
      return this.ismaster[index] === 0;
    },
    isType(index) {
      if (this.store.type[index] === "video") {
        return true;
      } else {
        return false;
      }
    },
    isLink,
  },

  computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < this.totalPages;
    },
  },
};
</script>
