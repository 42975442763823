<template>
  <div class="add_teacher">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
    <HeaderPages title=" إضافة قسم جديد" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="col-lg-6 col-md-6 col-sm-12 my-2">
        <label for="">الاسم</label>

        <div class="input">
          <input
            required
            type="text"
            placeholder="الاسم"
            v-model="form.title"
          />
        </div>
        <span class="error-feedback" v-if="v$.form.title.$error">
              {{ getErrorMessage(v$.form.title) }}
            </span>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

import { usecategoryAddStore } from "@/stores/category/categoryStoreAdd";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
  },
  data() {
    return {
      v$: useVuelidate(),
      store: usecategoryAddStore(),
      form: {
        title: "",
      },
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    submitForm() {
      this.v$.$validate();
      this.store.addCategory(this.form);
    },
  },
};
</script>

<style scoped>
.upload-icon {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px dashed #ccc;
  padding: 10px;
  margin-top: 10px;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
input#vehicle1 {
  width: 5%;
}
</style>
