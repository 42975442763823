import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import router from "@/router";

export const useAddModeratorsStore = defineStore("addModerators", {
  state: () => ({
    moderators: [],
  }),

  actions: {
    async addModerators(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key]) && key !== "certificateImagesSrc") {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (key !== "imageSrc" && key !== "certificateImagesSrc") {
            formData.append(key, data[key]);
          }
        });

        const response = await axios.post("add_employee", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {

          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم الإضافه  بنجاح",
          });
           router.push("/index-moderators");
          return response.data.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
        console.error("Error adding Moderetors:", error);
      }
    },
  }
});
