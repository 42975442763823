import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useExamsEditStore = defineStore("examsEdit", {
  state: () => ({
    exam: {},
    groups: [],
  }),
  actions: {
    async fetchExam(id) {
      try {
        const response = await axios.post("fetch_exam_details", {
          id: id,
        });
        if (response.data.status) {
          this.exam = response.data.data;
          // console.log(this.groups);
        } else {
          throw new Error("Failed to fetch groups data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async fetchGroup() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
          // console.log(this.groups);
        } else {
          throw new Error("Failed to fetch groups data");
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateExam(examsData) {
      try {
        // console.log(examsData, "examsData");
        // console.log(examsData.group_ids, "examsData.group_ids");
        const formData = new FormData();
        // Append each property to FormData
        Object.keys(examsData).forEach((key) => {
          const value = examsData[key];
          if (key === "questions" && Array.isArray(value)) {
            // Loop through questions array
            value.forEach((question, questionIndex) => {
              Object.keys(question).forEach((questionKey) => {
                if (questionKey === "type") {
                  // Append question_type
                  formData.append(
                    `questions[${questionIndex}][${questionKey}]`,
                    question[questionKey].id ?? question[questionKey]
                  );
                } else if (
                  questionKey === "answers" &&
                  Array.isArray(question[questionKey])
                ) {
                  // Loop through answers array within each question
                  question.answers.forEach((answer, answerIndex) => {
                    Object.keys(answer).forEach((answerKey) => {
                      if (answerKey === "is_correct") {
                        // Append boolean as 1 or 0
                        formData.append(
                          `questions[${questionIndex}][answers][${answerIndex}][${answerKey}]`,
                          answer[answerKey] ? 1 : 0
                        );
                      } else {
                        // Append other answer properties
                        formData.append(
                          `questions[${questionIndex}][answers][${answerIndex}][${answerKey}]`,
                          answer[answerKey]
                        );
                      }
                    });
                  });
                } else {
                  // Append other question properties
                  formData.append(
                    `questions[${questionIndex}][${questionKey}]`,
                    question[questionKey]
                  );
                }
              });
            });
          } else if (key === "group_ids" && Array.isArray(value)) {
            // Loop through group_ids array
            value.forEach((groupId, groupIndex) => {
              formData.append(`group_ids[${groupIndex}]`, groupId);
            });
          } else {
            // Append non-array values normally
            formData.append(key, value);
          }
        });

        // Make the API request
        const response = await axios.post("edit_exam", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Log response data
        console.log("API Response:", response.data);

        if (response.data.status) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الامتحان بنجاح",
          });
          await router.replace("/exams");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.message,
        });
        console.error(error);
      }
    },
  },
});
