<template>
  <div class="employees">
    <HeadersPages title="من نحن" button="+ اضافة من نحن" link="/add-whowe" icon="fa-solid fa-globe" />

    <div class="alll">
    <TablesPageVue
      :headers="tableHeaders"
      :rows="tableRows"
      :pages="tablePages"
      :showSelect="false"
      :editLink="editLink"
      :viewLink="viewLink"
      @delete="handleDeleteWhowe"
      :type="type"
    />
  </div>

  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useWhowweStore } from "@/stores/subHeaders/WhoweStore";
import { mapState } from "pinia";

export default {
  name: "WhoweIndex",
  components: {
    HeadersPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: [
        " id",
        " الصوره",
        " الوصف",
        " 	العنوان",
        " العنوان الفراعي",
      ],
      editLink: "/edit-whowe",
      viewLink: "/view-whowe",
    };
  },
  computed: {
    ...mapState(useWhowweStore, {
      whowe: (state) => state.whowe,
      type: (state) => state.type,
    }),
    tableRows() {
      // console.log(this.teachers, "ssss");
      return this.whowe.map((who) => [
        who.id,
        who.image,
        who.description,
        who.title,
        who.subtitle,
      ]);
    },
  },
  methods: {
    async handleDeleteWhowe(id) {
      const whoweStore = useWhowweStore();
      console.log(id, "lllllllllllllllllllllllllllll");

      await whoweStore.deleteWhowe(id);
    },
  },

  async mounted() {
    const whoweStore = useWhowweStore();
    await whoweStore.fetchWhowe();
  },
};
</script>
