<template>
  <div class="headers">
    <div class="hed">
      <i :class="icon"></i>
      <h4>{{ title }}</h4>
    </div>
    <router-link
      v-if="
        showButton &&
        [
          '/relation-index',
          '/employees',
          '/courses',
          '/groups',
          '/exams',
          '/index-question-banks',
          '/student-index',
          '/teacher-index',
          '/index-parent',
          '/index-moderators',
          '/index-header',
          '/index-counter',
          '/index-screen',
          '/opinion-client',
          '/whowe',
          '/index-serves',
          '/index-successpartners',
          '/index-features-services',
          '/index-blog',
          '/index-department',
          '/index-hashtag',
          '/index-competion',
          '/index-electroniclibrary',
          '/index-category',
          '/index-subscription',
          '/index-permission',
          '/index-jops',
        ].includes($route.path)
      "
      :to="link"
      class="add"
    >
      {{ button }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: "HeaderPages",
  props: {
    title: {
      type: String,
      required: true,
    },
    button: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    showButton: {
      type: Boolean,
      default: true, // Button is shown by default
    },
  },
};
</script>
