<template>
  <div class="questions-bank">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title="اضافة سؤال" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الامتحان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الامتحان"
              v-model="question_bank.question"
            />
            <span
              class="error-feedback"
              v-if="v$.question_bank.question.$error"
            >
              {{ getErrorMessage(v$.question_bank.question) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="">نوع السؤال</label>

          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            placeholder = "نوع السؤال"
            deselect-label=""
            v-model="question_bank.type"
            :options="ExamsOptions"
            track-by="id"
            label="title"
            :hidden-select="true"
          ></multiselect>
          <span class="error-feedback" v-if="v$.question_bank.type.$error">
            {{ getErrorMessage(v$.question_bank.type) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">درجة الامتحان</label>
          <div class="input">
            <input
              type="number"
              id="name"
              placeholder="أدخل درجة الامتحان"
              v-model="question_bank.degree"
              max="10"
            />
            <span class="error-feedback" v-if="v$.question_bank.degree.$error">
              {{ getErrorMessage(v$.question_bank.degree) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">المنهج</label>

          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            placeholder = "المنهج"
            deselect-label=""
            v-model="question_bank.curriculum_id"
            :options="curriculums"
            track-by="id"
            label="title"
            :hidden-select="true"
          ></multiselect>
          <span
            class="error-feedback"
            v-if="v$.question_bank.curriculum_id.$error"
          >
            {{ getErrorMessage(v$.question_bank.curriculum_id) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">الموسم</label>

          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            placeholder = "الموسم"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="question_bank.season_id"
            :options="seasons ?? []"
            track-by="id"
            label="title"
            :hidden-select="true"
          ></multiselect>
          <span class="error-feedback" v-if="v$.question_bank.season_id.$error">
            {{ getErrorMessage(v$.question_bank.season_id) }}
          </span>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <div class="all-btn mt-4">
            <button type="submit" class="save">اضافة</button>
            <button type="button" class="bake" @click="$router.go(-1)">
              رجوع
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useQuestionBankAddStore } from "@/stores/question_bank/QuestionBankAdd";
import { mapActions, mapState } from "pinia";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "QuestionBanksAdd",
  components: {
    Multiselect,
    HeaderPages,
  },
  data() {
    return {
      v$: useVuelidate(),
      question_bank: {
        question: "",
        type: "",
        degree: "",
        curriculum_id: "",
        season_id: "",
      },
      ExamsOptions: [
        { id: 1, title: "صوتي" },
        { id: 2, title: "اختياري" },
        { id: 3, title: "توصيل" },
        { id: 4, title: "تصحيح" },
      ],
    };
  },
  validations() {
    return {
      question_bank: {
        question: { required },
        type: { required },
        degree: { required },
        curriculum_id: { required },
        season_id: { required },
      },
    };
  },
  computed: {
    ...mapState(useQuestionBankAddStore, ["curriculums", "seasons"]),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    ...mapActions(useQuestionBankAddStore, [
      "fetchSeasons",
      "fetchCurriculums",
      "addQuestion",
    ]),
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      try {
        this.question_bank.curriculum_id = this.question_bank.curriculum_id.id;
        this.question_bank.season_id = this.question_bank.season_id.id;
        this.question_bank.type = this.question_bank.type.title;
        // this.question_bank.degree = parseInt(this.question_bank.degree);
        await this.addQuestion(this.question_bank);
      } catch (error) {
        console.log(error);
      }
    },
  },
  mounted() {
    this.fetchSeasons();
    this.fetchCurriculums();
  },
};
</script>

<style scoped lang="scss">
input.multiselect__input {
  display: none !important;
}
.inputs {
  margin-top: 0.5rem;
}
.all-btn {
  display: flex;
  gap: 5px;
  .save {
    width: 20%;
  }
}
</style>
