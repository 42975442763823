import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useSuccesspartnersAddStore = defineStore("successpartnersAdd", {
  state: () => ({
    Successpartners: []

  }),
  actions: {
    async addSuccesspartners(PartnerData) {
      try {
        const formData = new FormData();
        Object.keys(PartnerData).forEach((key) => {
          if (Array.isArray(PartnerData[key])) {
            PartnerData[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, PartnerData[key]);
          }
        });

        const response = await axios.post("add_partner", formData, {
          Successpartners: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status) {
          this.Successpartners.push(response.data.data);
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الشريك بنجاح",
          });
          router.push("/index-successpartners");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error while adding Successpartners:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    }

  }
});