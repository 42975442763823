<template>
  <div class="add-slider">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <HeaderPages title="من نحن" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <label for="title"> عنــوان</label>
          <div class="input">
            <input
              type="text"
              id="title"
              placeholder="اكتب هنا"
              v-model="whowe.title"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="subtitle"> عنوان فرعي</label>
          <div class="input">
            <input
              type="text"
              id="subtitle"
              placeholder="اكتب هنا"
              v-model="whowe.subtitle"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="description"> الوصــف</label>
          <br />
          <textarea
            id="description"
            rows="4"
            v-model="whowe.description"
            placeholder="اكتب هنا"
          ></textarea>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="fileUpload">ارفع صورة أو فيديو</label>
          <div>
            <input
              type="file"
              id="fileUpload"
              accept="image/*, video/*"
              @change="handleFileUpload"
            />
          </div>
          <div>
            <img
              class="up"
              v-if="isImage && fileURLS"
              :src="fileURLS"
              alt="Uploaded Image"
              width="100%"
            />
            <video
              v-if="isVideo && fileURLV"
              :src="fileURLV"
              controls
              width="100%"
            ></video>
          </div>
          <p v-if="uploadError" style="color: red">
            يرجى رفع صورة أو فيديو صحيح
          </p>
        </div>
      </div>

      <div class="row">
        <div
          v-for="(image, index) in whowe.features"
          :key="index"
          class="col-lg-6 col-md-6 col-12"
        >
          <div class="contant-uploader">
            <div class="avatar-uploader">
              <label for="avatar"> * اضافة صورة للسليدر</label>
              <input
                type="file"
                :id="'avatar' + index"
                @change="handleFileChange($event, index)"
                accept="image/*"
                ref="fileInput"
                style="display: none"
              />
              <div
                v-if="!image.image"
                class="upload-icon"
                @click="triggerFileInput(index)"
              >
                <i class="fa fa-camera"></i>
                <span>اختيار صورة</span>
              </div>
              <div v-if="image.image" class="avatar-preview">
                <img :src="image.image" alt="Avatar Preview" />
                <i
                  class="fa fa-times delete-icon"
                  @click="removeImage(index)"
                ></i>
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <label :for="'title' + index"> عنوان</label>
              <div class="input">
                <input
                  type="text"
                  :id="'title' + index"
                  placeholder="اكتب هنا"
                  v-model="image.title"
                />
              </div>
            </div>
            <div class="col-lg-12 col-md-12 col-12">
              <label :for="'description' + index"> الوصف</label>
              <br />
              <textarea
                :id="'description' + index"
                v-model="image.description"
                placeholder="اكتب هنا"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useSubHeadersEditStore } from "@/stores/subHeaders/editSubHeaderStore";

export default {
  name: "WhoweEdit",
  components: {
    HeaderPages,
  },
  data() {
    return {
      store: useSubHeadersEditStore(),
      fileURLS: null,
      fileURL: null,
      fileURLV: null,
      uploadError: false,
      isImage: false,
      isVideo: false,
      whowe: {
        title: "",
        subtitle: "",
        description: "",
        image: null,
        file: null,
        features: [
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
          { image: null, file: null, title: "", description: "", color: "" },
        ],
      },
    };
  },

  methods: {
    handleFileUpload(event) {
      const file = event.target.files[0];
      // console.log(file, "file");

      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.fileURLS = file.type.startsWith("image/")
            ? e.target.result
            : null;
          this.fileURLV = file.type.startsWith("video/")
            ? e.target.result
            : null;
          // store the file in the form data for submission
          this.whowe.image = file;
          // this.whowe.file = file;
          this.uploadError = false;
          this.isImage = file.type.startsWith("image/");
          this.isVideo = file.type.startsWith("video/");
        };
        reader.readAsDataURL(file);
      } else {
        this.uploadError = true;
        this.fileURLS = null;
        this.fileURLV = null;
      }
    },

    handleFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.whowe.features[index].image = e.target.result;
          this.whowe.features[index].file = file;
        };
        reader.readAsDataURL(file);
      }
    },
    dataURLtoBlob(dataURL) {
      if (!dataURL || !dataURL.includes(",")) {
        console.error("Invalid dataURL format:", dataURL);
        return null; // Return null or handle the error appropriately
      }

      try {
        const base64Data = dataURL.split(",")[1]; // Extract base64 part
        const byteString = atob(base64Data); // Decode base64
        const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);

        for (let i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        return new Blob([ab], { type: mimeString });
      } catch (error) {
        console.error("Error decoding base64 string:", error);
        return null;
      }
    },

    triggerFileInput(index) {
      this.$refs.fileInput[index].click();
    },

    removeImage(index) {
      this.whowe.features[index].image = "";
      this.whowe.features[index].file = null;
    },

    async getdata() {
      await this.store.fetchSubHeaders(this.$route.params.id);
      this.whowe = this.store.subHeaders;
      console.log(this.whowe.type, "whowe");

      if (this.whowe.type === "image") {
        this.fileURLS = this.whowe.image;
        this.isImage = true;
        console.log("image", this.whowe.image);
      } else if (this.whowe.type === "video") {
        this.fileURLV = this.whowe.image;
        console.log("video", this.whowe.image);

        this.isVideo = true;
      }

      if (!this.whowe.features || this.whowe.features.length === 0) {
        this.whowe.features = Array(4).fill({
          image: "",
          file: "",
          title: "",
          description: "",
          color: "",
        });
      } else {
        this.whowe.features.forEach((feature) => {
          if (feature.image) {
            this.fileURL = feature.image;
            this.isImage = true;
          }
        });
      }
    },

    async submitForm() {
      console.log("Form submitted:", this.whowe);
      this.whowe.image = this.dataURLtoBlob(
        this.fileURLS ? this.fileURLS : this.fileURLV
      );
      this.whowe.features.forEach((feature, index) => {
        if (feature.image) {
          const blob = this.dataURLtoBlob(feature.image);
          const fileName = `feature_image_${index}.png`; // أو أي اسم تفضله
          feature.file = new File([blob], fileName, { type: "image/png" }); // يمكنك تغيير نوع الصورة حسب الحاجة
        }
      });

      await this.store.updateSubHeaders(this.whowe, this.$route.params.id);
    },
  },

  mounted() {
    this.getdata();
  },
};
</script>

<style>
.contant-uploader {
  background: #06797e21;
  padding: 0.5rem; 
  border-radius: 7px;
  margin: 1rem 0;
}
.upload-icon {
  cursor: pointer;
}
.avatar-preview {
  position: relative;
}
.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
}
input[type="file"] {
  border: 1px solid var(--main);
  padding: 0.5rem;
  border-radius: 5px;
}
video {
  width: 100%;
  height: auto;
  margin-top: 1.5rem;
  border-radius: 10px;
}
.up {
  width: 200px;
  height: auto;
  border-radius: 7px;
}
</style>
