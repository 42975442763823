import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useRelationsStore = defineStore("relations", {
    state: () => ({
        relations: [],
    }),
    actions: {
        async fetchRelations() {
            try {
                const response = await axios.post("fetch_relations");
                if(response.data.status === true) 
                    {
                        this.relations = response.data.data.data;
                    } else {
                        console.log(response.data.message);
                    }
            } catch (error) {
                console.error(error);
            }
        },
        async deleteRelation(id) {
            try {
              const result = await Swal.fire({
                title: "هل انتا متاكد من عملية المسح?",
                text: "لن تتمكن من التراجع عن هذا!",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "نعم، احذفه!",
              });
      
              if (result.isConfirmed) {
                await axios.post("delete_relation", { id });
                this.relations = this.relations.filter((rel) => rel.id !== id);
                Swal.fire("تم الحذف!", "تم حذف صله القرابه", "success");
              }
            } catch (error) {
              console.error("Error deleting Relation:", error);
              Swal.fire(
                "Error!",
                "There was an error deleting the Relation.",
                "error"
              );
            }
          },
    },
});