<template>
  <div class="employees-add">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <HeaderPages title="تعديل مسابقة" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label> اضافة صوره للمدونه</label>
          <input
            id="avatar"
            ref="fileInput"
            accept="image/*"
            style="display: none"
            type="file"
            @change="handleFileChange"
          />
          <div v-if="!form.image" class="upload-icon" @click="triggerFileInput">
            <i class="fa fa-camera"></i>
            <span>اختيار صورة</span>
          </div>
          <span class="error-feedback" v-if="v$.form.image.$error">
            {{ v$.form.image.$errors[0].$message }}
          </span>
          <div v-if="image" class="avatar-preview">
            <img :src="image" alt="Avatar Preview" />
            <i class="fa fa-times delete-icon" @click="removeImage"></i>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المسابقة</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم المسابقة"
              v-model="form.name"
            />
            <span class="error-feedback" v-if="v$.form.name.$error">
              {{ v$.form.name.$errors[0].$message }}
            </span>
          </div>
        </div>
    

        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_date">تاريخ البدايه</label>
          <div class="input">
            <input
              type="date"
              name="start_date"
              id="start_date"
              v-model="form.start_date"
            />
            <span class="error-feedback" v-if="v$.form.start_date.$error">
              {{ v$.form.start_date.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="end_date">تاريخ النهايه</label>
          <div class="input">
            <input type="date" id="end_date" v-model="form.end_date" />
            <span class="error-feedback" v-if="v$.form.end_date.$error">
              {{ v$.form.end_date.$errors[0].$message }}
            </span>
          </div>
        </div>

        <!-- Reward Inputs -->
        <div class="col-lg-4 col-md-6 col-12" v-for="(reward, index) in form.rewards" :key="index">
          <label :for="'reward' + (index + 1)">
            {{ `الجائزة ${index + 1}` }}
          </label>
          <div class="input">
            <input
              type="number"
              :id="'reward' + (index + 1)"
              v-model="reward.reward"
              placeholder="أدخل الجائزة"
            />
          </div>
        </div>

        <div class="col-lg-12 col-md-6 col-12">
          <label for="description">الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea
              id="description"
              v-model="form.description"
              name="description"
            ></textarea>
            <span class="error-feedback" v-if="v$.form.description.$error">
              {{ v$.form.description.$errors[0].$message }}
            </span>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save" >تعديل</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>


<script>
import Multiselect from "vue-multiselect";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useCompetitionEditStore } from "@/stores/competition/CompetitionEditStore";
import { mapActions, mapState } from "pinia";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "GroupsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useCompetitionEditStore(),
      v$: useVuelidate(),
      image: null,

      form: {
        name: "",
        start_date: "",
        end_date: "",
        description: "",
        image: null,
        rewards: [{ stage: 1, reward: 0 }, { stage: 2, reward: 0 }, { stage: 3, reward: 0 }],
 
      },
    };
  },
  validations() {
    return {
      form: {
        name: { required },
        start_date: { required },
        end_date: { required },
        description: { required },
        image: { required },
        rewards: { required },

      },
    };
  },
  computed: {
    ...mapState(useCompetitionEditStore, ["form"]),
  },
  methods: {
    ...mapActions(useCompetitionEditStore, [
      "getCompetition",
      "updateCompetition",
    ]),
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.image = URL.createObjectURL(file);
      this.form.image = file;
    },
    removeImage() {
      this.image = null;
      this.form.image = null;
    },
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.updateCompetition(this.form);
      // store.updateCompetition(id, this.employee);
    },
  },
  async mounted() {
  const store = useCompetitionEditStore();
  await store.getCompetition(this.$route.params.id);
  this.form = { ...store.form };

  if (this.form.image) {
    this.image = this.form.image;
  }

  if (!this.form.rewards || this.form.rewards.length === 0) {
    this.form.rewards = [{ stage: 1, reward: 0 }, { stage: 2, reward: 0 }, { stage: 3, reward: 0 }];
  }
}

};
</script>

<style scoped></style>
