<template>
  <div class="add_teacher">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
    <HeaderPages :showButton="false" title="   تعديل هشتاج " />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar"> اضافة صورة </label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div
              v-if="!blogHashtag.image"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="imagePreview" class="avatar-preview">
              <img :src="imagePreview" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!--            <span class="error-feedback" v-if="v$.form.imageSrc.$error"-->
            <!--              >{{ v$.form.imageSrc.$errors[0].$message }}-->
            <!--            </span>-->
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> اسم الهشتاج </label>
          <div class="input">
            <input
              id="name"
              v-model="blogHashtag.title"
              placeholder="  اكتب اسم الهشتاج  "
              type="text"
            />
            <span
              v-if="v$.blogHashtag.description.$error"
              class="error-feedback"
            >
              {{ v$.blogHashtag.description.$errors[0].$message }}
            </span>
          </div>
        </div>

        <div class="col-lg-12 col-md-6 col-12">
          <label for="name"> الوصف </label>
          <textarea
            id="w3review"
            v-model="blogHashtag.description"
            name="w3review"
          ></textarea>
          <span v-if="v$.blogHashtag.description.$error" class="error-feedback">
            {{ v$.blogHashtag.description.$errors[0].$message }}
          </span>
        </div>
      </div>
      <div class="all-btn">
        <button class="save" type="submit">تعديل</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions, mapState } from "pinia";
import { useBlogHashtagEditStore } from "@/stores/BlogHashtag/BlogHashtagEditStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },

  data() {
    return {
      v$: useVuelidate(),
      imagePreview: null,
    };
  },
  validations() {
    return {
      blogHashtag: {
        title: { required },
        description: { required },
      },
    };
  },
  computed: {
    ...mapState(useBlogHashtagEditStore, ["blogHashtag"]),
  },
  methods: {
    ...mapActions(useBlogHashtagEditStore, [
      "UpdateBlogHashtag",
      "getBlogHashtag",
    ]),
    removeImage() {
      this.imagePreview = null;
      this.blogHashtag.image = null;
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      this.blogHashtag.image = file;
      const reader = new FileReader();
      reader.onload = () => {
        this.imagePreview = reader.result;
      };
      reader.readAsDataURL(file);
    },
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      this.UpdateBlogHashtag(this.blogHashtag);
    },
  },
  async mounted() {
    await this.getBlogHashtag(this.$route.params.id);
    this.imagePreview = this.blogHashtag?.image;
  },
};
</script>

<style scoped>
textarea {
  width: 100%;
  height: 100px;
  border: 1px solid var(--main);
  border-radius: 10px;
}
</style>
