import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useRelationsAddStore = defineStore("relationsAdd", {
 state : () => ({
  relations: [],
 }),
 actions: {


    async AddsRelations(RelationData) {
        try {
          const formData = new FormData();
          Object.keys(RelationData).forEach((key) => {
            if (Array.isArray(RelationData[key])) {
                RelationData[key].forEach((item) => {
                formData.append(`${key}[]`, item);
              });
            } else {
              formData.append(key, RelationData[key]);
            }
          });
  
          const response = await axios.post("add_relation", formData, {
            relations: { "Content-Type": "multipart/form-data" },
          });
  
          if (response.data.status) {
            this.relations.push(response.data.data); 
            Swal.fire({
              icon: "success",
              title: "تم الحفظ بنجاح",
              text: response.data.message || "تم إضافة صله القرابه بنجاح",
            });
          } else {
            Swal.fire({
              icon: "error",
              title: "خطأ",
              text: response.data.message,
            });
          }
        } catch (error) {
          console.error("Error while adding relations:", error);
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: error.response.data.message,
          });
        }
      },



//   async addRelations(relations) {
//    try {
//     const response = await axios.post("add_relation");
//     if(response.data.status === true){
        
//         this.relations = response.data;
        
//     }
//     console.log(this.relations);\
//    } catch (error) {
//     console.error(error);
//    }
//   },
 },

 })