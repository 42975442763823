<template>
  <div class="employees">
    <div class="head">

      <i class="fa-solid fa-square-poll-vertical"></i>
      <HeadersPages
      title=" نتائج الاختبارات"
      button="+ اضافة نتيجه"
      link="/add-exam-result"
      />
    </div>

    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :viewLink="viewLink"
        @delete="deleteServices"
        :ismaster="ismaster"
      />
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeadersPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useExamResultStore } from "@/stores/ExamResult/ExamResultStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";

import { mapActions, mapState } from "pinia";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "ServesIndex",
  components: {
    HeadersPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: [
        "ID",
        "الامتحان",
        "المدرس",
        "الطلاب",
        "عدد الاسئله",
        "عدد الاسئله الصحيحه",
        "الدرجة",
      ],
      viewLink: "/show-result",
    };
  },
  computed: {
    ...mapState(useExamResultStore, {
      examResults: (state) => state.examResults,
      ismaster: (state) => state.ismaster,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      const dataToDisplay = this.examResults;
      return dataToDisplay.map((service) => [
        service.id,
        service.exam?.name,
        service.teacher?.name,
        service.user?.name,
        service.answer_count,
        service.correct_answer_count,
        service.grade,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    ...mapActions(useExamResultStore, ["fetchExamResults"]),
    async handleSearch() {
      const servicestoreStore = useExamResultStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await servicestoreStore.fetchExamResults(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await servicestoreStore.fetchExamResults(1, this.word);

      if (servicestoreStore.examResults.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    async deleteServices(id) {
      const servicestoreStore = useExamResultStore();
      await servicestoreStore.deleteexamResults(id);
    },
    async handlePageChange(page) {
      const servicestoreStore = useExamResultStore();
      await servicestoreStore.fetchExamResults(page);
    },
  },
  async mounted() {
    this.fetchExamResults();
    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>
<style scoped lang="scss">
.head{
  display: flex;
  align-items: center;
i{
  margin-top: -33px;
    font-size: 29px;
    margin-left: 16px;
}
}
</style>
