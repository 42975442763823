<template>
  <header-pages
    title="المجموعات"
    button="+ إضافه مجموعه"
    link="/add-group"
    icon="fa-solid fa-people-group"
  ></header-pages>

  <div class="alll">
  <div class="search">
    <i class="fa-solid fa-magnifying-glass"></i>
    <input
      type="text"
      placeholder="بحث عن مجموعة..."
      v-model="word"
      @input="debouncedSearch"
    />
  </div>
  <tables-page-vue
    :headers="tableHeaders"
    :rows="tableRows"
    :pages="tablePages"
    :showSelect="false"
    :editLink="editLink"
    :viewLink="viewLink"
    @delete="handleDeleteGroup"
  />
  <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  <PaginationPage
      :currentPage="paginationCurrent"
      :totalPages="paginationLast"
      @page-changed="handlePageChange"
    />
  </div>


</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { useGroupsStore } from "@/stores/Groups/GroupsStore";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { debounce } from "lodash"; // استيراد دالة debounce

export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      useGroupsStore: useGroupsStore(),
      tableHeaders: ["ID", "اسم المجموعة", " تاريخ البدء", "تاريخ الانتهاء"],
      editLink: "/edit-group",
      viewLink: "/view-group",
    };
  },
  computed: {
    ...mapState(useGroupsStore, {
      groups: (state) => state.groups,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
      ismaster: (state) => state.ismaster,
    }),
    tableRows() {
      // console.log(this.employees, "ssss");
      const dataToDisplay = this.groups;
      return dataToDisplay.map((emp) => [
        emp.id,
        emp.title,
        emp.start_date,
        emp.end_date,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },

  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const GroupsStore = useGroupsStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await GroupsStore.fetchGroups(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await GroupsStore.fetchGroups(1, this.word);

      if (GroupsStore.groups.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },

    async handleDeleteGroup(id) {
      const GroupsStore = useGroupsStore();
      await GroupsStore.deleteGroup(id);
    },
    handlePageChange(page) {
      const curriculaStore = useGroupsStore();
      curriculaStore.fetchGroups(page);
    },
  },

  async mounted() {
    const GroupsStore = useGroupsStore();
    await GroupsStore.fetchGroups();

    this.debouncedSearch = debounce(() => {
      this.handleSearch(); // استخدم الدالة handleSearch
    }, 700); // تأخير 1500 مللي ثانية
  },
};
</script>

<style scoped>

</style>
