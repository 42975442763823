<template>
  <div class="Groups-add">
    <div class="heaader">
      <i class="fa-solid fa-pen-to-square edit"></i>
      <header-pages
        :showButton="false"
        link="/edit-group"
        title="تعديل مجموعة"
      />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <input
              v-model="group.title"
              placeholder="أدخل أسم المجموعة"
              type="text"
            />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">اختر المدرس</label>
          <multiselect
            v-model="group.teacher"
            :options="teachers"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="اختر المدرس"
            label="name"
            track-by="id"
          ></multiselect>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ البدايه </label>
            <input v-model="group.start_date" placeholder="من  " type="date" />
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ النهايه </label>
            <input v-model="group.end_date" placeholder="الي  " type="date" />
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">أختر الكورس</label>
          <multiselect
            v-model="group.course"
            :options="courses"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="أختر الكورس"
            label="name"
            track-by="id"
          ></multiselect>
          <!--          {{ group.course_value }}-->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input
              v-model="group.with_all_course_content"
              type="checkbox"
              value="group.with_all_course_content"
            />
            <label for="name">محتوي الكورس كامل</label>
          </div>
          <multiselect
            v-if="!group.with_all_course_content"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="group.stages"
            :disabled="allCourses"
            :multiple="true"
            :options="courseContents"
            label="title"
            placeholder="أختار محتوي الكورس"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input
              v-if="group.with_all_disability == 1"
              type="checkbox"
              :checked="group.with_all_disability == 1"
              name="with_all_disability"
              @input="toggleDisabilitySelect"
            />
            <input
              v-else
              type="checkbox"
              :value="0"
              name="with_all_disability"
              v-model="group.with_all_disability"
            />
            <label for="with_all_disability">جميع الاعاقات</label>
          </div>

          <multiselect
            v-if="group.with_all_disability != 1"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="group.disabilities"
            :multiple="true"
            :options="disabilities"
            label="title"
            placeholder="أختر الاعاقات"
            track-by="id"
          ></multiselect>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <div class="col-lg-12 col-md-12 col-12">
            <div v-for="(day, index) in days" :key="index" class="week">
              <div class="chek">
                <div class="input">
                  <!-- Bind the checkbox to the selected state of each day -->
                  <input
                    v-model="day.selected"
                    type="checkbox"
                    :checked="isSelected(day)"
                    @change="handleCheckboxChange(day)"
                  />
                </div>
                <!-- Display the day name dynamically -->
                <label :for="day.title">{{ day.title }}</label>
              </div>

              <!-- Only show the time inputs if the day is selected -->
              <div class="times">
                <div class="input">
                  <label for="time_start">من</label>
                  <!-- Bind each day's time_start value -->
                  <input
                    :value="getTimestart(day)"
                    @input="updateTime($event.target.value, day, 'start_time')"
                    type="time"
                  />
                </div>
                <div class="input">
                  <label for="time_end">إلى</label>
                  <!-- Bind each day's time_end value -->
                  <input
                    :value="getTimeend(day)"
                    @input="updateTime($event.target.value, day, 'end_time')"
                    type="time"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">تعديل</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions, mapState } from "pinia";
import { useGroupsEditStore } from "@/stores/Groups/GroupsEditStore";

export default {
  name: "GroupsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      selectedDay: null,
      allDisabilities: false,
      allCourses: false,
      CoursesOptions: [],
      Courses_value: [],
      disabilities: [],
      DisiplityOptions: [],
      TeatcherOptions: [],
      Teatcher_value: [],
      GroupsOptions: [],
      Groups_value: [],
    };
  },
  computed: {
    ...mapState(useGroupsEditStore, [
      "courseContents",
      "disabilities",
      "courses",
      "days",
      "teachers",
      "group",
    ]),
  },
  methods: {
    ...mapActions(useGroupsEditStore, [
      "fetchCourseContent",
      "fetchDisabilities",
      "fetchCourses",
      "fetchTeachers",
      "fetchDays",
      "updateGroup",
      "fetchGroupDetails",
    ]),

    // Check if the day is selected by comparing the day.id to the group.days
    isSelected(day) {
      return this.group?.days?.some(
        (d) =>
          d.id === day.id &&
          d.selected && // تحقق مما إذا كان اليوم محددًا
          d.start_time && // تأكد من وجود start_time
          d.end_time // تأكد من وجود end_time
      );
    },

    // Handle checkbox state change
    handleCheckboxChange(day) {
      const matchingDay = this.group?.days?.find((d) => d.id === day.id);
      if (matchingDay) {
        matchingDay.selected = day.selected; // Ensure to update the state of the day
      }
    },

    
    formatTime(time) {
      if (!time || time === "غير محدد") return "00:00"; 

      const [hours, minutes] = time.split(":");
      if (hours && minutes) {
        return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
      }

      return "00:00";
    },
    getTimestart(day) {
      const matchingDay = this.group?.days?.find((d) => d.id === day.id);
      return matchingDay && matchingDay.start_time;
    },

    getTimeend(day) {
      const matchingDay = this.group?.days?.find((d) => d.id === day.id);
      return matchingDay && matchingDay.end_time;
    },

    updateTime(newTime, day, timeType) {
      const formattedTime = this.formatTime(newTime);
      console.log(`Updated Time for ${timeType}: ${formattedTime}`);

      const matchingDay = this.group?.days?.find((d) => d.id === day.id);
      if (matchingDay) {
        matchingDay[timeType] = formattedTime; 
      } else {
        this.group?.days.push({
          id: day.id,
          title: day.title,
          [timeType]: formattedTime,
        });
      }
    },

    submitForm() {
      const selectedDays = this.days
        .filter((day) => day.selected) 
        .map((day) => {
          const start_time = this.getTimestart(day); 
          const end_time = this.getTimeend(day); 

          console.log(
            `Selected day: ${day.title}, start_time: ${start_time}, end_time: ${end_time}`
          );

          return {
            day_id: day.id,
            start_time: start_time,
            end_time: end_time,
          };
        });

      console.log("Selected days array:", selectedDays);
      selectedDays.forEach((day) => {
        day.start_time = this.formatTime(day.start_time);
        day.end_time = this.formatTime(day.end_time);
      });

      console.log("Formatted days array:", selectedDays);

      this.group.days = selectedDays;

      this.group.with_all_course_content = this.group.allCourses ? 1 : 0;
      this.group.with_all_disability = this.group.with_all_disability ? 1 : 0;
      this.group.disabilities =
        this.group.disabilities?.map((item) => item.id) || [];
      this.group.stages = this.group.stages.map((item) => item.id) || null;
      this.group.course_id = this.group.course.id || null;
      this.group.teacher_id = this.group.teacher?.id || null;

      this.updateGroup(this.group);
    },

    addNewDay() {
      const newDay = {
        id: new Date().getTime(),
        title: "يوم جديد",
        selected: false,
        start_time: "00:00",
        end_time: "00:00",
      };

      this.days.push(newDay);
    },

    setDaySelectionState(groupId) {
      if (this.group && Array.isArray(this.group.days)) {
        this.days.forEach((day) => {
          const matchingDay = this.group.days.find(
            (groupDay) => groupDay.id === day.id
          );
          if (matchingDay) {
            day.selected = true;
          } else {
            day.selected = false;
          }
        });
      }
    },
    goToEditPage(groupId) {
      this.$router.push({ name: "editGroup", params: { id: groupId } });
    },
  },
  mounted() {
    // this.setDaySelectionState();
    const groupId = this.$route.params.id;
    this.fetchCourseContent();
    this.fetchDisabilities();
    this.fetchCourses();
    this.fetchTeachers();
    this.fetchDays();
    this.fetchGroupDetails(groupId)
      .then(() => {
        this.loading = false;
        this.setDaySelectionState(groupId);
      })
      .catch((error) => {
        console.error("خطأ أثناء تحميل تفاصيل المجموعة:", error);
      });
    if (this.group && Array.isArray(this.group.days)) {
      this.days.forEach((day) => {
        // إذا كان اليوم موجودًا في `group.days`، قم بتعيين selected إلى true
        const matchingDay = this.group.days.find(
          (groupDay) => groupDay.id === day.id
        );
        day.selected = matchingDay ? matchingDay.selected : false;
      });
    }
  },
};
</script>

<style lang="scss" scoped>
#all {
  display: flex;
  margin-top: 15px;
}

.dayes {
  margin-right: 34px;
}

textarea {
  width: 100%;
  height: 70px;
  border: 1px solid var(--main);
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

.multiselect--disabled .multiselect__current,
.multiselect--disabled .multiselect__select {
  background: none;
  color: #a6a6a6;
}

#start {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid var(--main);
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

#end {
  width: 280px;
  height: 40px;
  margin-right: 15px;
  border: 1px solid var(--main);
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  resize: none;
}

label {
  margin-top: 8px;
}

.chek {
  label {
    margin: 0 !important;
    padding-right: 5px;
  }
}
</style>
