<template>
  <div class="app-parent">
    <HeaderPages title="+ تطبيق المعلم" :showButton="false" />

    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">إضافة صورة للتطبيق</label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div
              v-if="!Appteacher.image"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="Appteacher.image" class="avatar-preview">
              <img :src="Appteacher.image" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الرابط Android </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="Appteacher.android_url"
            />
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.android.$error">
            {{ getErrorMessage(v$.appparent.android) }}
          </span> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الرابط IOS </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="Appteacher.ios_url"
            />
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.ios.$error">
            {{ getErrorMessage(v$.appparent.ios) }}
          </span> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الوصف</label>
          <br />
          <div class="textarea">
            <textarea
              id="w3review"
              name="w3review"
              v-model="Appteacher.description"
            ></textarea>
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.description.$error">
            {{ getErrorMessage(v$.appparent.description) }}
          </span> -->
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import {useAppTeacherStore} from "@/stores/AppTeacher/AppTeacherStore" ;
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useAppTeacherStore(),
    v$: useVuelidate(),
    Appteacher: {
      android_url: "",
      ios_url: "",
      description: "",
      type: 1,
      image: null,
      certificate: null,
      },
    };
  },
  validations() {
    return {
      Appteacher: {
        // image: { required },
        // image: { required },
        description: { required },
        ios: { required },
        android: { required },
      },
    };
  },

  //   computed:{
  // ...mapState(useAppparentStore,{
  //   appparent: (state) => state.appparent,
  // }),
  //   },

  methods: {
   async fetchTeacher() {
    await this.store.fetchDatateacher();
      this.Appteacher = this.store.Appteacher;

      
    },
 
      async submitForm() {
    console.log("Submitting form with Appteacher:", this.Appteacher);
    this.Appteacher.type = 1;
    this.store.AddAppteacher(this.Appteacher); 
    },

    // إزالة الصورة
    removeImage() {
      this.Appteacher.image = null;
    },

    // تفعيل اختيار الملف للصورة
    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    // التعامل مع تغيير الصورة
    handleFileChange(event) {
  const file = event.target.files[0];
  
  if (file) {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    // Validate image type
    if (validImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.Appteacher.image = e.target.result;  // Data URL for image preview
      };
      reader.readAsDataURL(file);
      
      this.Appteacher.imageFile = file;  // Actual file for FormData
    } else {
      alert('Please upload a valid image file (jpg, jpeg, png).');
    }
  }
}

,

    // تفعيل اختيار الملف للشهادة
    triggerCertificateInput() {
      this.$refs.certificateInput.click();
    },

    // // التعامل مع تغيير الشهادة
    // handleCertificateChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.appparent.certificateFile = file; // تخزين الشهادة كملف فعلي
    //   }
    // },

    // إزالة الشهادة
    removeCertificate() {
      this.Appteacher.certificateFile = null;
    },
  },

  mounted() {
    this.fetchTeacher();
  },
};
</script>

<style scoped>
textarea {
  margin-top: 0.5rem;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
</style>
