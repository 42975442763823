<template>
  <div class="add-serves">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title="إضافة خدمات مميزه جديدة" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">اضافة صورة</label>
            <input
              type="file"
              ref="fileInput"
              @change="handleFileChange"
              accept="image/*"
              style="display: none"
            />
            <div
              v-if="!data.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="data.imageSrc" class="avatar-preview">
              <img :src="data.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-12">
          <label for="name"> الاسم</label>
          <div class="input">
            <input
              type="text"
              v-model="data.title"
              id="name"
              placeholder="اكتب هنا "
            />
            <span v-if="!data.title && formSubmitted" class="error-message"
              >هذا الحقل مطلوب</span
            >
          </div>
        </div>

        <div class="col-lg-12 col-md-12 col-12">
          <label for="description"> عنوان فرعي</label>
          <br />
          <div class="input">
            <input type="text" id="description" v-model="data.subtitle" />
            <span v-if="!data.subtitle && formSubmitted" class="error-message"
              >هذا الحقل مطلوب</span
            >
          </div>
        </div>
        <div class="col-lg-12 col-md-12 col-12">
          <label for="description"> الوصف</label>
          <br />
          <div class="input-textarea">
            <textarea id="description" v-model="data.description"></textarea>
            <span
              v-if="!data.description && formSubmitted"
              class="error-message"
              >هذا الحقل مطلوب</span
            >
          </div>
        </div>

        <div
          class="col-12 my-3 border-bottom py-3"
          v-for="(feature, index) in data.features && data.features.length
            ? data.features
            : []"
          :key="index"
        >
          <div class="d-flex gap-4">
            <div class="w-50">
              <label for="feature-name">اسم الميزه</label>
              <div class="input">
                <input
                  type="text"
                  v-model="feature.title"
                  placeholder="اكتب هنا"
                />
              
              </div>
              <span
                  v-if="!feature.title && formSubmitted"
                  class="error-message" >هذا الحقل مطلوب</span >
            </div>
            <div class="w-25">
              <label for="feature-color">اللون</label>
              <div class="input">
                <div class="card flex justify-center">
                  <ColorPicker v-model="feature.color" />
                </div>
              </div>
            </div>
          </div>

          <div class="flex justify-between gap-4">
            <div class="w-full">
              <label for="description">وصف الميزه</label>
              <div class="input-textarea">
                <textarea v-model="feature.description"></textarea>
                <span
                  v-if="!feature.description && formSubmitted"
                  class="error-message"
                  >هذا الحقل مطلوب</span
                >
              </div>
            </div>

            <div class="w-full">
              <div class="avatar-uploader">
                <label for="feature-image">اضافة صورة</label>
                <input
                  type="file"
                  :ref="'featureFileInput' + index"
                  @change="handleFeatureFileChange($event, index)"
                  accept="image/*"
                  style="display: none"
                />
                <div
                  v-if="!feature.imageSrc"
                  class="upload-icon"
                  @click="triggerFeatureFileInput(index)"
                >
                  <i class="fa fa-camera"></i>
                  <span>اختيار صورة</span>
                </div>
                <div v-if="feature.imageSrc" class="avatar-preview">
                  <img :src="feature.imageSrc" alt="Feature Image Preview" />
                  <i
                    class="fa fa-times delete-icon"
                    @click="removeFeatureImage(index)"
                  ></i>
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex justify-between my-3 gap-3">
            <div
              class="btn btn-danger text-light"
              @click="removeFeature(index)"
              v-if="data.features.length > 1 && index !== 0"
            >
              <i class="fa fa-minus text-light"></i>
            </div>

            <div class="btn btn-success text-light" @click="addFeature">
              <i class="fa fa-plus text-light"></i>
            </div>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import ColorPicker from "primevue/colorpicker";
import { useServicesStore } from "@/stores/featureServices/featureServicesStoreAdd";
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "ServicesAdd",
  components: {
    HeaderPages,
    ColorPicker,
  },
  data() {
    return {
      v$: useVuelidate(),
      store: useServicesStore(),
      data: {
        imageSrc: "",
        image: null,
        title: "",
        subtitle: "",
        description: "",
        features: [
          {
            title: "",
            description: "",
            image: null,
            imageSrc: "",
            color: "#000",
          },
        ],
      },
      formSubmitted: false, // إضافة متغير لمتابعة ما إذا كانت البيانات قد تم إرسالها
    };
  },
  validations() {
    return {
      data: {
        title: { required },
        subtitle: { required },
        // imageSrc: { required },
      },
      features: {
        $each: {
          title: { required },
          description: { required },
          // imageSrc: { required },
          color: { required },
        },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    addFeature() {
      this.data.features.push({
        title: "",
        description: "",
        // image: null,
        // imageSrc: "",
        color: "#000",
      });
    },
    removeFeature(index) {
      this.data.features.splice(index, 1);
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.data.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.data.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.data.image = null;
      this.data.imageSrc = "";
    },
    handleFeatureFileChange(event, index) {
      const file = event.target.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.data.features[index].imageSrc = e.target.result;
          this.data.features[index].image = file;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFeatureFileInput(index) {
      // Access the dynamic reference for the specific file input in the loop
      if (this.$refs[`featureFileInput${index}`]) {
        this.$refs[`featureFileInput${index}`][0].click();
      } else {
        console.error(`Ref for feature index ${index} not found`);
      }
    },
    removeFeatureImage(index) {
      this.data.features[index].image = null;
      this.data.features[index].imageSrc = "";
    },
    submitForm() {
      this.formSubmitted = true; // تحديد أن النموذج تم إرساله
      this.v$.$validate(); // Validate all fields
      if (this.v$.$error) return; // If there are validation errors, do not submit
      this.store.addServices(this.data);
    },
  },
};
</script>

<style scoped>
.error-message {
  color: red !important;
    font-size: 0.875rem !important;
    background: transparent !important;
    display: contents;
}
</style>
