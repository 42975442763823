import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useSubscriptionAddStore = defineStore("subscriptionAdd", {
  state: () => ({
    subscription: [],
    courses: [],
    groups: [],
    students: [],
  }),
  actions: {
    async fetchCourses() {
      try {
        const response = await axios.post("fetch_courses");
        if (response.data.status) {
          this.courses = response.data.data.data; // Assuming this should set courses
        } else {
          throw new Error("Failed to fetch courses data");
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchStudents() {
      try {
        const response = await axios.post("organization_fetch_students");
        if (response.data.status) {
          this.students = response.data.data;
        } else {
          throw new Error("Failed to fetch students data");
        }
      } catch (error) {
        console.error(error);
      }
    },

    async fetchGroups() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
        } else {
          throw new Error("Failed to fetch groups data");
        }
      } catch (error) {
        console.error(error);
      }
    },

    async addSubscription(data) {
      try {
        const formData = new FormData();
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else {
            formData.append(key, data[key]);
          }
        });

        const response = await axios.post("add_subscripe_group", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status) {
          this.subscription.push(response.data.data);
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الاشتراك بنجاح",
          });
          router.push("/index-subscription");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error while adding subscription:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
  },
});
