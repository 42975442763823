<template>
  <div class="serves">
    <HeaderPages
      title="الخدمات"
      button="+ إضافة خدمة جدول"
      link="/add-serves"
      icon="fa-solid fa-globe"
    />

    <div class="alll">
      <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن الخدمة ..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div>
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="deleteServices"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
      <PaginationPage
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { useServicesStore } from "@/stores/services/servicesStoreIndex";
import { mapState } from "pinia";
import { debounce } from "lodash";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";

export default {
  name: "ServesIndex",
  components: {
    HeaderPages,
    TablesPageVue,
    PaginationPage,
  },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "صورة", "العنوان الرئيسي", "العنوان الفرعي"],
      editLink: "/edit-serves",
      viewLink: "/view-serves",
    };
  },
  computed: {
    ...mapState(useServicesStore, {
      ServicesStoresss: (state) => state.ServicesStore,
      ...mapState(usePaginationStore, {
        paginationCurrent: (state) => state.current_page,
        paginationFrom: (state) => state.from,
        paginationLast: (state) => state.last_page,
        paginationPer: (state) => state.per_page,
        paginationTo: (state) => state.to,
        paginationTotal: (state) => state.total,
      }),
    }),
    tableRows() {
      const dataToDisplay = this.ServicesStoresss || [];
      return dataToDisplay.map((service) => [
        service.id,
        service.image,
        service.title,
        service.sub_title,
      ]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const servicestoreStore = useServicesStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await servicestoreStore.fetchServicesStore(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await servicestoreStore.fetchServicesStore(1, this.word);

      if (servicestoreStore.ServicesStore.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    async deleteServices(id) {
      const servicestoreStore = useServicesStore();
      await servicestoreStore.deleteServicesStore(id);
    },
    async handlePageChange(page) {
      const servicestoreStore = useServicesStore();
      await servicestoreStore.fetchServicesStore(page);
    },
  },
  async mounted() {
    const servicesStore = useServicesStore(); // Corrected name
    await servicesStore.fetchServicesStore(); // Call the correct method
    console.log(servicesStore);
    this.debouncedSearch = debounce(() => {
      this.handleSearch();
    }, 700);
  },
};
</script>

<style scoped>

</style>
