import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
export const useFeatureServicesStoreedit = defineStore("ServicesStoreedit", {
  state: () => {
    return {
      ServicesStore: {},
    };
  },
  actions: {
    async getServicesStore(id) {
      try {
        const response = await axios.post(
          "organization_fetch_service_feature_details",
          {
            id: id,
          }
        );
        this.ServicesStore = response.data.data;
        // console.log(this.ServicesStore);
      } catch (error) {
        console.error(error);
      }
    },
    async editServicesStore(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        for (const key in data) {
          if (
            data.hasOwnProperty(key) &&
            key !== "features" &&
            key !== "imageSrc"
          ) {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        }

        data.features.forEach((item, index) => {
          for (const key in item) {
            if (
              item.hasOwnProperty(key) &&
              item[key] !== null &&
              key !== "imageSrc" &&
              key !== "image"
            ) {
              if (key === "image") {
                if (item[key] instanceof File) {
                  formData.append("image", item[key]);
                }
              }
              // تحقق من عدم كون القيمة null
              formData.append(`features[${index}][${key}]`, item[key]);
            }
          }
        });

        // Send the FormData to the API
        const response = await axios.post(
          "organization_edit_service_feature",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة الخدمه بنجاح",
          });
          router.push("/index-features-services");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        console.error("Error editing ServicesStore:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: response.data.message,
        });
      }
    },
  },
});
