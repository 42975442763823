<template>
  <div class="courses">
    <header-pages
      title="الاسكرينات "
      button=" + إضافه اسكرين   "
      link="/add-screen"
      icon="fa-solid fa-globe"
    />

    <div class="alll">
      <!-- <div class="search">
        <i class="fa-solid fa-magnifying-glass"></i>
        <input
          type="text"
          placeholder="بحث عن اسكرين..."
          v-model="word"
          @input="debouncedSearch"
        />
      </div> -->
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="handleDeleteGroup"
      />
      <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>

      <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useScreenStore } from "@/stores/Screenss/screenStore";
import { debounce } from "lodash";

export default {
  components: { HeaderPages, TablesPageVue, PaginationPage },
  data() {
    return {
      word: "",
      errorMessage: "",
      debouncedSearch: null,
      tableHeaders: ["ID", "الصورة"],
      editLink: "/edit-screen",
      viewLink: "/screen-details",
    };
  },
  computed: {
    ...mapState(useScreenStore, {
      screens: (state) => state.screens,
    }),
    ...mapState(usePaginationStore, {
      paginationCurrent: (state) => state.current_page,
      paginationFrom: (state) => state.from,
      paginationLast: (state) => state.last_page,
      paginationPer: (state) => state.per_page,
      paginationTo: (state) => state.to,
      paginationTotal: (state) => state.total,
    }),
    tableRows() {
      if (!Array.isArray(this.screens)) {
        console.warn("screens is not an array:", this.screens);
        return [];
      }
      return this.screens.map((scr) => [scr.id, scr.image]);
    },
    tablePages() {
      return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    },
  },
  methods: {
    handleInputChange() {
      this.errorMessage = "";
      this.debouncedSearch();
    },
    async handleSearch() {
      const screenStore = useScreenStore();
      if (this.word.trim() === "") {
        this.errorMessage = "";
        await screenStore.getScreen(1);
        return;
      } else {
        this.errorMessage = "";
      }

      await screenStore.getScreen(1, this.word);

      if (screenStore.screens.length === 0) {
        this.errorMessage = "لم يتم العثور على أي كلمة";
      } else {
        this.errorMessage = "";
      }
    },
    handlePageChange(page) {
      const screenStore = useScreenStore();
      screenStore.getScreen(page);
    },
    handleDeleteGroup(id) {
      const screenStore = useScreenStore();
      screenStore.deleteScreen(id);
    },
  },
  mounted() {
    const screenStore = useScreenStore();
    screenStore.getScreen();

    this.debouncedSearch = debounce(() => {
      this.handleSearch();
    }, 700);
  },
};
</script>

<style scoped>

</style>
