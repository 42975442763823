<template>
  <div class="add-serves">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title="إضافة خدمات جديدة" :showButton="false" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-12 col-12">
          <div class="avatar-uploader">
            <label for="avatar">إضافة صورة</label>
            <input
              type="file"
              id="avatar"
              @change="handleFileChange"
              accept="image/*"
              ref="fileInput"
              style="display: none"
            />
            <div
              v-if="!form.imageSrc"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="form.imageSrc" class="avatar-preview">
              <img :src="form.imageSrc" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
            <!-- <span class="error-feedback" v-if="v$.form.imageSrc.$error">
              {{ getErrorMessage(v$.form.imageSrc) }}
            </span> -->
          </div>
        </div>

        <div class="col-lg-6 col-md-12 col-12">
          <label for="subtitle">العنوان الرئيسي</label>
          <div class="input-textarea">
            <textarea
              id="subtitle"
              v-model="form.title"
              placeholder="اكتب هنا"
            ></textarea>
          </div>
          <span class="error-feedback" v-if="v$.form.title.$error">
            {{ getErrorMessage(v$.form.title) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-12 col-12">
          <label for="subtitle">العنوان الفرعي</label>
          <div class="input-textarea">
            <textarea
              id="subtitle"
              v-model="form.sub_title"
              placeholder="اكتب هنا"
            ></textarea>
          </div>
          <span class="error-feedback" v-if="v$.form.title.$error">
            {{ getErrorMessage(v$.form.sub_title) }}
          </span>
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { mapState } from "pinia";
import { useServesAddStore } from "@/stores/serves/addServesStore";
import Swal from "sweetalert2";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";

export default {
  name: "ServicesAdd",
  components: {
    HeaderPages,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        // imageSrc: "",
        // image: null,
        title: "",
        sub_title: "",
      },
    };
  },
  validations() {
    return {
      form: {
        // imageSrc: { required },
        title: { required },
        sub_title: { required },
      },
    };
  },
  computed: {
    ...mapState(useServesAddStore, {
      serves: (state) => state.serves,
    }),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    async submitForm() {
      this.v$.$validate(); 
      if (this.v$.$error) {
        return;
      }

      try {
        const servesStore = useServesAddStore();
        if (!servesStore) {
          throw new Error("Failed to load serves store");
        }
        await servesStore.addServes(this.form); 
        this.$router.push("/index-serves"); 
      } catch (error) {
        console.error("Error in submitForm:", error);
      }
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.form.image = file;
        const reader = new FileReader();
        reader.onload = (e) => {
          this.form.imageSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      }
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    removeImage() {
      this.form.image = null;
      this.form.imageSrc = "";
    },
  },
};
</script>

<style scoped>
.error-feedback {
  color: red;
  font-size: 0.85rem;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}
</style>
