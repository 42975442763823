import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useQuestionBankEditStore = defineStore("question_bank_edit", {
  state: () => ({
    question_bank: {},
    seasons: [],
    curriculums: [],
  }),
  actions: {
    async getQuestion(id) {
      try {
        const response = await axios.post(`fetch_question_bank_details`, {
          id: id,
        });
        if(response.data.status === true) {
        this.question_bank = response.data.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateQuestion(questionData) {
      try {
        const response = await axios.post("edit_question_bank", questionData);
        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل السؤال بنجاح",
          });
          await router.push({ name: "index-question-banks" });
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: error.response.data.message,
          });
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
    async getSeasons() {
      try {
        const response = await axios.get("organization_fetch_seasons");
        this.seasons = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getCurriculums() {
      try {
        const response = await axios.get("organization_fetch_curriculums");
        this.curriculums = response.data.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
});
