<template>
  <div class="employees-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <HeaderPages title="اضافة امتحان" :showButton="false" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم الأمتحان</label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="أدخل أسم الأمتحان"
              v-model="exam.name"
            />
            <span class="error-feedback" v-if="v$.exam.name.$error">
              {{ getErrorMessage(v$.exam.name) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="duration">مدة الأمتحان</label>
          <div class="input">
            <input type="time" id="duration" v-model="exam.duration" />
            <span class="error-feedback" v-if="v$.exam.duration.$error">
              {{ getErrorMessage(v$.exam.duration) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_date">تاريخ البدايه</label>
          <div class="input">
            <input
              type="date"
              name="start_date"
              id=""
              v-model="exam.start_date"
            />
            <span class="error-feedback" v-if="v$.exam.start_date.$error">
              {{ getErrorMessage(v$.exam.start_date) }}
            </span>
          </div>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="date"> تاريخ النهايه </label>
          <div class="input">
            <input type="date" v-model="exam.end_date" />
            <span class="error-feedback" v-if="v$.exam.end_date.$error">
              {{ getErrorMessage(v$.exam.end_date) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="start_time">وقت البدايه</label>
          <div class="input">
            <input
              type="time"
              name="start_time"
              id=""
              v-model="exam.start_time"
            />
            <span class="error-feedback" v-if="v$.exam.start_time.$error">
              {{ getErrorMessage(v$.exam.start_time) }}
            </span>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="end_time"> وقت النهايه </label>
          <div class="input">
            <input type="time" name="end_time" id="" v-model="exam.end_time" />
            <span class="error-feedback" v-if="v$.exam.end_time.$error">
              {{ getErrorMessage(v$.exam.end_time) }}
            </span>
          </div>
        </div>

        <!--        <div class="col-lg-6 col-md-6 col-12">-->
        <!--          <label for="question_count">عدد الاسئله </label>-->
        <!--          <div class="input">-->
        <!--            <input-->
        <!--              type="number"-->
        <!--              id="question_count"-->
        <!--              v-model="exam.question_count"-->
        <!--            />-->
        <!--            <span class="error-feedback" v-if="v$.exam.question_count.$error">-->
        <!--              {{ getErrorMessage(v$.exam.question_count) }}-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-lg-6 col-md-6 col-12">-->
        <!--          <label for="degree">الدرجة </label>-->
        <!--          <div class="input">-->
        <!--            <input type="number" id="degree" v-model="exam.degree" />-->
        <!--            <span class="error-feedback" v-if="v$.exam.degree.$error">-->
        <!--              {{ getErrorMessage(v$.exam.degree) }}-->
        <!--            </span>-->
        <!--          </div>-->
        <!--        </div>-->
        <!--        <div class="col-lg-6 col-md-6 col-sm-12">-->
        <!--          <label for="">نوع الامتحان</label>-->
        <!--          <multiselect-->
        <!--            v-model="exam.exam_type"-->
        <!--            :options="ExamsOptions"-->
        <!--            :close-on-select="false"-->
        <!--            track-by="id"-->
        <!--            label="title"-->
        <!--          ></multiselect>-->
        <!--          <span class="error-feedback" v-if="v$.exam.exam_type.$error">-->
        <!--            {{ getErrorMessage(v$.exam.exam_type) }}-->
        <!--          </span>-->
        <!--        </div>-->
        <!--        <div class="col-lg-6 col-md-6 col-sm-12">-->
        <!--          <label for="">نوع الدرجه</label>-->
        <!--          <multiselect-->
        <!--            v-model="exam.degree_type"-->
        <!--            :options="degreeOptions"-->
        <!--            :close-on-select="true"-->
        <!--            track-by="id"-->
        <!--            label="title"-->
        <!--          ></multiselect>-->
        <!--          <span class="error-feedback" v-if="v$.exam.degree_type.$error">-->
        <!--            {{ getErrorMessage(v$.exam.degree_type) }}-->
        <!--          </span>-->
        <!--        </div>-->
        <div class="col-lg-12 col-md-6 col-sm-12">
          <label for="">اختر المجموعه</label>
          <multiselect
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"
            label="title"
            placeholder="اختر المجموعه"
            select-label=""
            deselect-label=""
            track-by="id"
            v-model="group_valus"
            :options="groups"
            :multiple="true"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="">نوع الاسئلة</label>
          <button type="button" @click="addQuestion" class="save w-100">
            <i class="fa fa-plus"></i> اضافة اسئلة
          </button>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
          <div
            class="questions border-top mt-2"
            v-for="(question, question_index) in exam.questions"
            :key="question_index"
          >
            <div class="question">
              <div class="row">
                <div class="col-lg-6 col-md-6 col-12">
                  <label for="">السؤال</label>
                  <div class="input">
                    <input type="text" v-model="question.question" />
                    <!-- <span
                      class="error-feedback"
                      v-if="v$.exam.questions[question_index].question.$error"
                    >
                      {{
                        getErrorMessage(v$.exam.questions[question_index].question)
                      }}
                    </span> -->
                    <span
                      v-if="!question.question && isSubmitted"
                      class="error-feedback"
                    >
                      field is required
                    </span>
                  </div>
                </div>
                <div class="col-lg-6 col-md-6 col-12">
                  <div class="inputs">
                    <label for="">نوع السؤال</label>

                    <multiselect
                      :close-on-select="true"
                      :hide-selected="true"
                      select-label=""
                      deselect-label=""
                      placeholder="نوع السؤال"
                      v-model="question.type"
                      :options="ExamsOptions"
                      track-by="id"
                      label="title"
                      :hidden-select="true"
                      :clear-on-select="true"
                    ></multiselect>
                    <span
                      v-if="!question.type && isSubmitted"
                      class="error-feedback"
                    >
                      field is required
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 col-md-6 col-12">
                <label for="">الدرجة </label>
                <div class="input">
                  <input type="number" v-model="question.degree" />
                  <!-- <span
                    class="error-feedback"
                    v-if="v$.exam.questions[question_index].degree.$error"
                  >
                    {{
                      getErrorMessage(v$.exam.questions[question_index].degree)
                    }}
                  </span> -->
                  <span
                    v-if="!question.degree && isSubmitted"
                    class="error-feedback"
                  >
                    field is required
                  </span>
                </div>
              </div>
              <div class="col-lg-8 col-md-6 col-12">
                <div
                  class="answers"
                  v-for="(answer, answer_index) in question.answers"
                  :key="answer_index"
                >
                  <div
                    class="d-flex justify-content-between align-items-center gap-2"
                  >
                    <div class="w-100">
                      <label for="">الجواب</label>
                      <div class="input">
                        <input type="text" v-model="answer.answer" />

                        <span
                          v-if="!answer.answer && isSubmitted"
                          class="error-feedback"
                        >
                          field is required
                        </span>
                        <!-- <span
                          class="error-feedback"
                          v-if="
                            v$.exam.questions[question_index].answers[
                              answer_index
                            ].answer.$error
                          "
                        >
                          {{
                            getErrorMessage(
                              v$.exam.questions[question_index].answers[
                                answer_index
                              ].answer
                            )
                          }}
                        </span> -->
                      </div>
                    </div>
                    <div class="d-flex align-items-center gap-2">
                      <div>
                        <label for="" class="text-nowrap m-0"
                          >الجواب الصحيح</label
                        >
                        <div class="input">
                          <input
                            type="radio"
                            :name="`is_correct${question_index}`"
                            value="1"
                            class="w-25"
                            v-model="answer.is_correct"
                          />
                        </div>
                      </div>
                      <button
                        type="button"
                        @click="removeAnswer(question_index, answer_index)"
                        class="btn btn-outline-danger"
                      >
                        <i class="fa fa-trash text-danger"></i>
                      </button>
                      <button
                        type="button"
                        @click="addAnswer(question_index)"
                        class="btn"
                      >
                        <i class="fa fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="button"
              @click="removeQuestion(question_index)"
              class="btn btn-outline-danger"
            >
              حذف السؤال
            </button>
          </div>
        </div>
      </div>

      <div class="all-btn mt-4">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>
<script>
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import { useExamsAddStore } from "@/stores/exams/ExamsAddStore";
import { mapActions, mapState } from "pinia";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
export default {
  name: "ExamsAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      isSubmitted: false,
      store: useExamsAddStore(),
      exam: {
        name: "",
        start_date: "",
        start_time: "",
        end_date: "",
        end_time: "",
        duration: "",
        question_count: "",
        degree: "",
        group_ids: [],
        exam_type: null,
        degree_type: null,
        questions: [],
      },
      ExamsOptions: [
        { id: 1, title: "صوتي" },
        { id: 2, title: "اختياري" },
        { id: 3, title: "توصيل" },
        { id: 4, title: "تصحيح" },
      ],
      degreeOptions: [
        { id: 1, title: "نسبه مؤيه" },
        { id: 2, title: "درجه" },
      ],
      groupOptions: [],

      degree_valus: null,
      group_valus: null,
    };
  },
  validations() {
    return {
      exam: {
        // degree: { required },
        name: { required },
        start_date: { required },
        start_time: { required },
        end_date: { required },
        end_time: { required },
        duration: { required },
        // question_count: { required },
        // exam_type: { required },
        // degree_type: { required },
        // questions: {
        //   $each: {
        //     question: { required },
        //     degree: { required },
        //     answers: {
        //       $each: {
        //         answer: { required },
        //       },
        //     },
        //   },
        // },
      },
    };
  },

  computed: {
    exams: (state) => state.exams,
    ...mapState(useExamsAddStore, ["groups"]),
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    ...mapActions(useExamsAddStore, ["addExam"]),

    addQuestion() {
      this.exam.questions.push({
        type: "", // e.g., 1 for multiple choice, etc.
        question: "", // The question text
        answers: [
          {
            answer: "", // First answer
            is_correct: false, // Is this answer correct?
          },
        ],
        degree: "", // The score/degree for this question
      });
    },
    removeQuestion(index) {
      this.exam.questions.splice(index, 1);
    },
    removeAnswer(question_index, answer_index) {
      this.exam.questions[question_index].answers.splice(answer_index, 1);
    },
    addAnswer(question_index) {
      this.exam.questions[question_index].answers.push({
        answer: "", // New answer text
        is_correct: false, // Default correctness set to false
      });
    },
    submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;

      this.isSubmitted = true;
      this.exam.exam_type = this.exam.exam_type?.id;

      this.exam.degree_type = this.exam.degree_type?.id;
      this.exam.start_time = this.formatTime(this.exam.start_time);
      this.exam.end_time = this.formatTime(this.exam.end_time);
      this.exam.duration = this.formatTime(this.exam.duration);
      this.exam.group_ids = this.group_valus.map((g) => g.id);
      this.addExam(this.exam);
    },
    formatTime(time) {
      // Validate input format
      const timePattern = /^\d{1,2}:\d{2}$/; // Match HH:mm format
      if (!timePattern.test(time)) {
        throw new Error("Invalid time format. Expected HH:mm.");
      }

      // Split time into hours and minutes
      const [hours, minutes] = time.split(":").map(Number);

      // Validate hours and minutes
      if (hours < 0 || hours > 23) {
        throw new Error("Invalid hour value. Must be between 0 and 23.");
      }
      if (minutes < 0 || minutes > 59) {
        throw new Error("Invalid minute value. Must be between 0 and 59.");
      }

      // Return formatted time
      return `${hours.toString().padStart(2, "0")}:${minutes
        .toString()
        .padStart(2, "0")}`;
    },
  },
  async mounted() {
    await this.store.fetchgroup();
  },
};
</script>
<style lang="scss" scoped>
button.btn.btn-outline-danger {
  margin-top: 1rem;
}
</style>
