<template>
  <div class="courses">
    <header-pages title="الهيدر" button="+ إضافه هيدر " link="/add-header" icon="fa-solid fa-globe" />

    <div class="alll">
      <TablesPageVue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
        :editLink="editLink"
        :viewLink="viewLink"
        @delete="deleteServices"
        :ismaster="ismaster"
      />
      <pagination-page
        :currentPage="paginationCurrent"
        :totalPages="paginationLast"
        @page-changed="handlePageChange"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import PaginationPage from "@/components/pagination/PaginationPage.vue";
import { mapState } from "pinia";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";
import { useHeaderStore } from "@/stores/Header/HeaderStore";
export default {
  components: { HeaderPages, TablesPageVue },
  data() {
    return {
      tableHeaders: ["ID", "صوره", "الوصف", "العنوان", "العنوان الفرعي"],
      editLink: "/edit-header",
      viewLink: "/view-header",
    };
  },
  computed: {
    ...mapState(useHeaderStore, {
      header: (state) => state.header,
    }),
    // ...mapState(usePaginationStore, {
    //   paginationCurrent: (state) => state.current_page,
    //   paginationFrom: (state) => state.from,
    //   paginationLast: (state) => state.last_page,
    //   paginationPer: (state) => state.per_page,
    //   paginationTo: (state) => state.to,
    //   paginationTotal: (state) => state.total,
    // }),
    tableRows() {
      return this.header.map((head) => [
        head.id,
        head.image,
        head.title,
        head.subtitle,
        head.description,
        //   head.description,
      ]);
    },
    // tablePages() {
    //   return Array.from({ length: this.paginationLast }, (_, i) => i + 1);
    // },
  },
  methods: {
    // handlePageChange(page) {
    //   const paginationStore = usePaginationStore();
    //   paginationStore.setCurrentPage(page);
    // },
    handleDeleteGroup(id) {
      const headerStore = useHeaderStore();
      headerStore.deleteHeader(id);
    },
  },
  mounted() {
    const headerStore = useHeaderStore();
    headerStore.getHeader();
  },
};
</script>
