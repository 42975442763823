<template>
  <div class="blog">
    <header-pages title=" الشكاوي"  :showButton="false" icon = "fa-solid fa-toolbox" />
    <div class="alll">
      <tables-page-vue
        :headers="tableHeaders"
        :rows="tableRows"
        :pages="tablePages"
        :showSelect="false"
      />
    </div>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import TablesPageVue from "@/components/tables/TablesPage.vue";
import { mapState } from "pinia";
import { usecomplaintsStore } from "@/stores/complaints/complaintsStore";
export default {
  name: "GroupsIndex",
  components: {
    HeaderPages,
    TablesPageVue,
  },
  data() {
    return {
      tableHeaders: [ "ID", "اسم الشخص", "	الالبريد الالكتروني",  " 	رقم الهاتف" ,   "	نص الشكاوي"],
     
    };
  },

  computed: {
    ...mapState(usecomplaintsStore, {
      complaints: (state) => state.complaints || [], // Safe fallback
    }),
    tableRows() {
      const dataToDisplay = this.complaints || [];
      return dataToDisplay.map((comp) => [
        comp.id,
        comp.name,
        comp.email,
        comp.phone,
        comp.message,
      ]);
    },
  },
  async mounted() {
    const complaintsStore = usecomplaintsStore();
    try {
      await complaintsStore.getComplaints();
    } catch (error) {
      console.error("Error loading complaints:", error);
    }
  },
};
</script>
