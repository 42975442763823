import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";
import router from "@/router";


export const useRelationsEditStore = defineStore("relationsEdit", {
    state: () => ({
        relations: [],

    }),
    actions: {

        async getRelations(id) {
            try {
                const response = await axios.post("fetch_relation_details", { id });
                if (response.data.status) {
                    this.relations = response.data.data;
                } else {
                    console.error("Error fetching relations:", response.data.message);
                }
            } catch (error) {
                console.error("API call failed:", error);
            }

        },

        async updateRelations(formData) {
            try {
                const response = await axios.post("edit_relation", formData, {
                    relations: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                if (response.data.status) {
                    this.relations = response.data.data;
                    Swal.fire({
                        icon: "success",
                        title: "تم التعديل بنجاح",
                        text: response.data.message || "تم تعديل صله القرابه بنجاح",
                      });
                    await router.push("/relation-index");
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "خطأ",
                        text: response.data.message,
                      });
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: response.data.message,
                  });
            }
        },




    }
})
