<template>
  <div class="Groups-add">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
      <header-pages :showButton="false" link="/groups" title="اضافة مجموعة" class="mb-4" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name">أسم المجموعة</label>
          <div class="input">
            <input
              v-model="group.title"
              placeholder="أدخل أسم المجموعة"
              type="text"
            />
          </div>
          <span class="error-feedback" v-if="v$.group.title.$error">
            {{ getErrorMessage(v$.group.title) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">اختر المدرس</label>
          <multiselect
            v-model="group.Teatcher_value"
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            placeholder="اختر المدرس"
            :options="TeatcherOptions"
            label="name"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.group.Teatcher_value.$error">
            {{ getErrorMessage(v$.group.Teatcher_value) }}
          </span>

          <!--          {{ group.Teatcher_value }}-->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ البدايه </label>
            <input v-model="group.start_date" placeholder="من  " type="date" />
          </div>
          <span class="error-feedback" v-if="v$.group.start_date.$error">
            {{ getErrorMessage(v$.group.start_date) }}
          </span>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="input">
            <label for="date">تاريخ النهايه </label>
            <input v-model="group.end_date" placeholder="الي  " type="date" />
          </div>
          <span class="error-feedback" v-if="v$.group.end_date.$error">
            {{ getErrorMessage(v$.group.end_date) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <label for="Courses">أختر الكورس</label>
          <multiselect
            :clear-on-select="true"
            :hide-selected="true"
            select-label=""
            deselect-label=""
            v-model="group.course_value"
            :close-on-select="true"
            :options="courseOptions"
            placeholder="أختر الكورس"
            label="name"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.group.course_value.$error">
            {{ getErrorMessage(v$.group.course_value) }}
          </span>

          <!--          {{ group.course_value }}-->
        </div>

        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input v-model="group.allCourses" type="checkbox" />
            <label for="name">محتوي الكورس كامل</label>
          </div>
          <multiselect
            v-if="!group.allCourses"
            :clear-on-select="true"
            :close-on-select="true"
            :hide-selected="true"

            select-label=""
            deselect-label=""
            v-model="group.stage_value"
            :disabled="allCourses"
            :multiple="true"
            :options="stageOptions"
            label="title"
            placeholder = "أختار محتوي الكورس"
            track-by="id"
          ></multiselect>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <div class="check-div">
            <input
              v-model="group.allDisabilities"
              type="checkbox"
              @change="toggleDisabilitySelect"
            />
            <label for="name">جميع الاعاقات</label>
            <span
              class="error-feedback"
              v-if="
                v$.group.allDisabilities.$error && group.allDisabilities == 0
              "
            >
              {{ getErrorMessage(v$.group.allDisabilities) }}
            </span>
          </div>
          <multiselect
            v-if="!group.allDisabilities"
            :clear-on-select="true"
            :close-on-select="true"
            v-model="group.disabilities"
            :disabled="allDisabilities"
            :options="DisiplityOptions"
            label="title"
            :multiple="true"
            placeholder="أختر الاعاقات"
            track-by="id"
          ></multiselect>
          <span class="error-feedback" v-if="v$.group.allDisabilities.$error">
            {{ getErrorMessage(v$.group.allDisabilities) }}
          </span>
        </div>

        <div class="col-lg-6 col-md-12 col-12">
          <div class="dropdown">
            <button
              class="btn dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              الأيام
            </button>
            <ul class="dropdown-menu">
              <li>
                <div v-for="(day, index) in days" :key="index" class="week">
                  <div class="chek">
                    <div class="input">
                      <!-- Bind the checkbox to the selected state of each day -->
                      <input v-model="day.selected" type="checkbox" />
                    </div>
                    <!-- Display the day name dynamically -->
                    <label :for="day.title">{{ day.title }}</label>
                  </div>

                  <!-- Only show the time inputs if the day is selected -->
                  <div v-if="day.selected" class="times">
                    <div class="input">
                      <label for="time_start">من</label>
                      <!-- Bind each day's time_start value -->
                      <input v-model="day.time_start" type="time" />
                    </div>
                    <div class="input">
                      <label for="time_end">إلى</label>
                      <!-- Bind each day's time_end value -->
                      <input v-model="day.time_end" type="time" />
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div class="all-btn">
        <button class="save" type="submit">حفظ</button>
        <button class="bake" type="button" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useGroupsAddStore } from "@/stores/Groups/GroupsAddStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "GroupsAdd",
  data() {
    return {
      v$: useVuelidate(),
      groupAddStore: useGroupsAddStore(),
      group: {
        title: "",
        allDisabilities: false,
        start_date: "",
        end_date: "",
        allCourses: false,
        days: [],
        Teatcher_value: null,
        disabilities: [], // Initialize as an empty array
        stage_value: [], // Initialize as an empty array
        course_value: [],
      },
      courseOptions: [], // Initialize as an empty array
      stageOptions: [], // Initialize as an empty array
      DisiplityOptions: [], // Initialize as an empty array
      TeatcherOptions: [], // Initialize as an empty array
      days: [], // Initialize as an empty array
    };
  },

  validations() {
    return {
      group: {
        title: { required },
        start_date: { required },
        end_date: { required },
        Teatcher_value: { required },
        course_value: { required },
        allDisabilities: { required },
      },
    };
  },
  components: {
    HeaderPages,
    Multiselect,
  },
  async mounted() {
    await this.groupAddStore.fetchCourses();
    await this.groupAddStore.fetchTeachers();
    console.log(this.groupAddStore.teachers, "ddddddddddddddddddd");
    await this.groupAddStore.fetchCourseContent();
    await this.groupAddStore.fetchDisabilities(); // Ensure this fetch happens
    await this.groupAddStore.fetchDays();

    this.courseOptions = this.groupAddStore.courses || [];
    this.TeatcherOptions = this.groupAddStore.teachers || [];
    this.stageOptions = this.groupAddStore.courseContents || [];
    this.DisiplityOptions = this.groupAddStore.disabilities || []; // Ensure this is populated correctly
    this.days = this.groupAddStore.days || [];
  },

  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    submitForm() {
      // Construct the days array in the required format
      this.v$.$validate();
      if (this.v$.$error);
      const selectedDays = this.days
        .filter((day) => day.selected)
        .map((day) => ({
          day_id: day.id,
          start_time: day.time_start,
          end_time: day.time_end,
        }));

      // Assign the selected days in the format: days[0][start_time], days[0][end_time], days[0][day_id]
      this.group.days = selectedDays;

      // Handling allCourses and allDisabilities as boolean-to-integer
      this.group.with_all_course_content = this.group.allCourses ? 1 : 0;
      this.group.with_all_disability = this.group.allDisabilities ? 1 : 0;

      if (this.group.allDisabilities) {
        // If "allDisabilities" is checked, the backend expects an empty array for `disabilities`
        this.group.disabilities = [];
      } else {
        // If "allDisabilities" is not checked, ensure you provide the selected disabilities
        this.group.disabilities =
          this.group.disabilities.map((item) => item.id) || [];
      }
      // Ensure multiselect values are set to their corresponding ids
      this.group.stages = this.group.stage_value.map((item) => item.id) || null;
      this.group.course_id = this.group.course_value.id || null;
      // this.group.disabilities = null;

      this.group.teacher_id = this.group.Teatcher_value?.id || null;
      this.group.Teatcher_value = null;
      this.group.course_value = null;
      this.group.stage_value = null;
      this.group.allCourses = null;
      this.group.allDisabilities = null;
      // Submit the group data
      this.groupAddStore.addGroups(this.group);
    },
  },
};
</script>
<style lang="scss" scoped>
.show {
  width: 100%;
  padding: 20px;
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;

  box-shadow: 1px -2px 1px lightgray;
}

button.btn.dropdown-toggle {
  width: 100%;
  height: 38px;
  margin-top: 41px;
  margin-bottom: 15px;
  border: 1px solid var(--main);
  &:hover {
    transform: unset !important;
  }
}

.chek {
  label {
    margin: 0 !important;
    padding-right: 5px;
  }
}
</style>
