import router from "@/router";
import axios from "axios";
import { defineStore } from "pinia";
import Swal from "sweetalert2";

export const useOpinionClientStore = defineStore("opinionClient", {
  state: () => {
    return {};
  },
  actions: {
    async addOpinionClient(data) {
      try {
        const formData = new FormData();
        console.log(data, "data");

        // Loop through data to populate FormData
        Object.keys(data).forEach((key) => {
          if (Array.isArray(data[key])) {
          } else {
            // Skip imageSrc to avoid sending it
            // if (key === "imageSrc") return;
            // Append other fields
            formData.append(key, data[key]);
          }
        });

        // Send the FormData to the API
        const response = await axios.post("add_opinion", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم الحفظ بنجاح",
            text: response.data.message || "تم إضافة التعليق بنجاح",
          });
          router.push("/opinion-client");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          }); throw new Error(response.data.message);
        }
      } catch (error) {
        console.error(error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: error.response.data.message,
        });
      }
    },
  },
});
