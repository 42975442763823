import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";


export const useAppTeacherStore = defineStore("appteacher", {
    state: () => ({
        Appteacher: [],
    }),
    actions: {
        async AddAppteacher(appteatcherData) {
            console.log("Appstudent data inside store:", appteatcherData); // Log the data being passed to the store
            
            const formData = new FormData();
         
          
            // If an image file exists, append it
            if (appteatcherData.imageFile) {
               
              console.log("Appending image:", appteatcherData.imageFile);
              formData.append("image", appteatcherData.imageFile);
            }
          
            // Ensure 'type' is appended correctly
            if (appteatcherData.type) {
              console.log("Appending type:", appteatcherData.type); // Check if 'type' is correctly included
              formData.append("type", appteatcherData.type);
            }
          
            // Log other form data
            Object.keys(appteatcherData).forEach((key) => {
              if (key !== "imageFile" && key !== "image" && key !== "certificateFile") {
                if (appteatcherData[key] !== null && appteatcherData[key] !== undefined && appteatcherData[key] !== "") {
                  formData.append(key, appteatcherData[key]);
                }
              }
            });
            console.log("Form data being sent:");
            formData.forEach((value, key) => {
              console.log(`${key}: ${value}`);
            });
                
            try {
              const response = await axios.post("store_application_info", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
          
              if (response.data.status === true) {
                this.Appteacher = response.data.data;
                Swal.fire({
                  icon: "success",
                  title: "Success",
                  text: "AppTeatcher added successfully",
                });
                router.push("/"); // Redirect to a different route after successful submission
                console.log(this.Appteacher, "teacher data updated");
              } else {
                console.error("Error fetching teacher.");
              }
            } catch (error) {
              console.error("Error:", error);
            }
          },
          
          
    
        async fetchDatateacher() {
          try {
            const response = await axios.post("fetch_application_info", {
              type: 1,
            });
            if (response.data.status === true) {
              this.Appteacher = response.data.data;
              console.log(this.Appteacher, "teacher data updated");
            } else {
              console.error("Error fetching teacher.");
            }
          } catch (error) {
            console.error("Error:", error);
          }
        },
      },
    });
  