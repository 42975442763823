<template>
  <div class="employees">
    <h4> <i class="fa-solid fa-gears"></i>الاعدادات العامة</h4>
    <div class="form-setting">
      <h6 class="change">تغير كلمة المرور</h6>
      <form @submit.prevent="submitForm">
        <label for="old_password">ادخل كلمة المرور الحاليه</label>
        <div class="input">
          <input
            :type="showOldPassword ? 'text' : 'password'"
            id="old_password"
            v-model="form.old_password"
            placeholder="ادخل كلمة المرور الحاليه"
          />
          <button
            type="button"
            class="toggle-password"
            @click="showOldPassword = !showOldPassword"
          >
            <i :class="showOldPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
          </button>
        </div>
        <label for="new_password">ادخل كلمة المرور الجديده</label>
        <div class="input">
          <input
            :type="showNewPassword ? 'text' : 'password'"
            id="new_password"
            v-model="form.new_password"
            placeholder="ادخل كلمة المرور الجديده"
          />
          <button
            type="button"
            class="toggle-password"
            @click="showNewPassword = !showNewPassword"
          >
            <i :class="showNewPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"></i>
          </button>
        </div>
        <label for="new_password_confirmation">تأكيد كلمة المرور</label>
        <div class="input">
          <input
            :type="showConfirmPassword ? 'text' : 'password'"
            id="new_password_confirmation"
            v-model="form.new_password_confirmation"
            placeholder="تأكيد كلمة المرور"
          />
          <button
            type="button"
            class="toggle-password"
            @click="showConfirmPassword = !showConfirmPassword"
          >
            <i
              :class="showConfirmPassword ? 'fas fa-eye-slash' : 'fas fa-eye'"
            ></i>
          </button>
        </div>
        <div class="all-btn" style="margin-top: 20px">
          <button type="submit" class="save">حفظ</button>
          <button type="button" class="bake" @click="$router.go(-1)">
            رجوع
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "sitingPage",
  data() {
    return {
      form: {
        old_password: "",
        new_password: "",
        new_password_confirmation: "",
      },
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false,
    };
  },
  methods: {
    async submitForm() {
      try {
        const res = await axios.post("organization-change-password", this.form);
        if (res.data.status === true) {
          Swal.fire({
            icon: "success",
            title: "تم بنجاح",
            text: res.data.message,
          });
          this.$router.push("/");
        } else {
          Swal.fire({
            icon: "error",
            title: "فشل",
            text: res.data.message,
          });
        }
      } catch (error) {
        console.error("API Error:", error);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.input {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.185);
  border-radius: 15px;
  input {
    border: 0 !important;
    box-shadow: unset !important;
    border-radius: 0 !important;
  }
}
.toggle-password {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
}
button.save {
  width: 20% !important;
}
.change{
  font-size: 18px;
    font-family: 'regular';
    margin-right: 10px;
    color: #ff0b0bba;
    margin-top: 30px;
}

</style>
