import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useStudentsEditStore = defineStore("StudentsEdit", {
  state: () => ({
    students: [],
    countries: [],
    bloods: [],
    groups: [],
    parent: [],
    relationship: [],
  }),
  actions: {
    async fetchUserDetails(id) {
      try {
        const response = await axios.post("fetch_user_details", { id });
        if (response.data.status) {
          return response.data.data;
        } else {
          console.log("Error fetching user details");
        }
      } catch (error) {
        console.error("Error fetching user details:", error);
      }
    },

    async fetchCountries() {
      try {
        const response = await axios.get("organization_fetch_countries");
        if (response.data.status) {
          this.countries = response.data.data;
          console.log(this.countries);
        } else {
          console.log("Error fetching countries");
        }
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    },

    async fetchBloods() {
      try {
        const response = await axios.get("organization_fetch_blood_types");
        if (response.data.status) {
          this.bloods = response.data.data;
        } else {
          console.log("Error fetching blood types");
        }
      } catch (error) {
        console.error("Error fetching blood types:", error);
      }
    },

    async fetchGroups() {
      try {
        const response = await axios.get("organization_fetch_groups");
        if (response.data.status) {
          this.groups = response.data.data;
        } else {
          console.log("Error fetching groups");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },
    async fetchParent() {
      try {
        const response = await axios.post("organization_fetch_parents");
        if (response.data.status) {
          this.parent = response.data.data;
        } else {
          console.log("Error fetching groups");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },
    async fetchrelationship() {
      try {
        const response = await axios.post("organization_fetch_relations");
        if (response.data.status) {
          this.relationship = response.data.data;
        } else {
          console.log("Error fetching groups");
        }
      } catch (error) {
        console.error("Error fetching groups:", error);
      }
    },

    async editStudents(data) {
      try {
        const formData = new FormData();

        Object.keys(data).forEach((key) => {
          if (
            Array.isArray(data[key]) &&
            key !== "images" &&
            key !== "groups"
          ) {
            data[key].forEach((item) => {
              formData.append(`${key}[]`, item);
            });
          } else if (
            key !== "imageSrc" &&
            key !== "country" &&
            key !== "blood_type" &&
            key !== "groups" &&
            key !== "parent_id" &&
            key !== "relation_id"
          ) {
            if (key === "image") {
              if (data[key] instanceof File) {
                formData.append("image", data[key]);
              }
            } else {
              formData.append(key, data[key]);
            }
          }
        });
        console.log("FormData contents:");
        for (let [key, value] of formData.entries()) {
          console.log(`${key}: ${value}`);
        }

        // Send FormData to the API
        const response = await axios.post("edit_user", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });


        if (response.data.status === true) {
          this.students = response.data.data;
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل الطالب بنجاح",
          });
          router.push("/student-index");
          return response.data;
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });        }
      } catch (error) {
        console.error("Error adding teacher:", error);
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: response.data.message,
        });
      }
    },
  },
});



