<template>
  <div class="app-parent">
    <HeaderPages title="+ تطبيق ولي امر" :showButton="false" />

    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-6 col-md-6 col-12">
          <div class="avatar-uploader">
            <label for="avatar">إضافة صورة للتطبيق</label>
            <input
              id="avatar"
              ref="fileInput"
              accept="image/*"
              style="display: none"
              type="file"
              @change="handleFileChange"
            />
            <div
              v-if="!appparent.image"
              class="upload-icon"
              @click="triggerFileInput"
            >
              <i class="fa fa-camera"></i>
              <span>اختيار صورة</span>
            </div>
            <div v-if="appparent.image" class="avatar-preview">
              <img :src="appparent.image" alt="Avatar Preview" />
              <i class="fa fa-times delete-icon" @click="removeImage"></i>
            </div>
          </div>
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الرابط Android </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="appparent.android_url"
            />
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.android.$error">
            {{ getErrorMessage(v$.appparent.android) }}
          </span> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الرابط IOS </label>
          <div class="input">
            <input
              type="text"
              id="name"
              placeholder="اكتب هنا "
              v-model="appparent.ios_url"
            />
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.ios.$error">
            {{ getErrorMessage(v$.appparent.ios) }}
          </span> -->
        </div>
        <div class="col-lg-6 col-md-6 col-12">
          <label for="name"> الوصف</label>
          <br />
          <div class="textarea">
            <textarea
              id="w3review"
              name="w3review"
              v-model="appparent.description"
            ></textarea>
          </div>
          <!-- <span class="error-feedback" v-if="v$.appparent.description.$error">
            {{ getErrorMessage(v$.appparent.description) }}
          </span> -->
        </div>
      </div>

      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { useAppparentStore } from "@/stores/Appparent/AppparentStore";

import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      store: useAppparentStore(),
      v$: useVuelidate(),
      appparent: {
        android_url: "",
        ios_url: "",
        description: "",
        type: 3,
        image: null, // تخزين صورة واحدة فقط هنا
        certificate: null, // تخزين شهادة واحدة فقط هنا
      },
    };
  },
  validations() {
    return {
      appparent: {
        image: { required },
        // image: { required },
        description: { required },
        ios: { required },
        android: { required },
      },
    };
  },

  //   computed:{
  // ...mapState(useAppparentStore,{
  //   appparent: (state) => state.appparent,
  // }),
  //   },

  methods: {
   async fetchparent() {
    await this.store.fetchAppparent();
      this.appparent = this.store.appparent;

      
    },
    async submitForm() {
      // console.log(this.appparent ,'DIAA');
      this.appparent.type = 3;
      this.store.AddAppparent(this.appparent);
    },

    removeImage() {
      this.appparent.image = null;
    },

    triggerFileInput() {
      this.$refs.fileInput.click();
    },

    handleFileChange(event) {
  const file = event.target.files[0];
  
  if (file) {
    const validImageTypes = ['image/jpeg', 'image/png', 'image/jpg'];
    
    // Validate image type
    if (validImageTypes.includes(file.type)) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.appparent.image = e.target.result;  // Data URL for image preview
      };
      reader.readAsDataURL(file);
      
      this.appparent.imageFile = file;  // Actual file for FormData
    } else {
      alert('Please upload a valid image file (jpg, jpeg, png).');
    }
  }
}

,

    triggerCertificateInput() {
      this.$refs.certificateInput.click();
    },

      // handleCertificateChange(event) {
    //   const file = event.target.files[0];
    //   if (file) {
    //     this.appparent.certificateFile = file; // تخزين الشهادة كملف فعلي
    //   }
    // },

    removeCertificate() {
      this.appparent.certificateFile = null;
    },
  },

  mounted() {
    this.fetchparent();
  },
};
</script>

<style scoped>
textarea {
  margin-top: 0.5rem;
}

.avatar-preview {
  position: relative;
  margin-top: 10px;
}

.avatar-preview img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}

.delete-icon {
  position: absolute;
  top: 0;
  right: 0;
  background: rgb(232 240 254);
  border-radius: 50%;
  padding: 5px;
  cursor: pointer;
  color: red;
  font-size: 20px;
}

.certificates-preview {
  display: flex;
  flex-wrap: wrap;
}

.certificate-image {
  position: relative;
  margin: 10px;
}

.certificate-image img {
  max-width: 100px;
  max-height: 100px;
  object-fit: cover;
}
.checkbox {
  margin: 1rem 0;
}
</style>
