import { defineStore } from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import { usePaginationStore } from "@/stores/pagination/PaginationStore";

export const useSubscriptionStore = defineStore("subscription", {
  state: () => ({
    subscriptions: [],
  }),
  actions: {
    async getSubscriptions(page = 1) {
      try {
        const response = await axios.post(
          `fetch_subscripe_groups?page=${page}`
        );
        const paginationStore = usePaginationStore();
        const { current_page, from, last_page, per_page, to, total } =
          response.data.data.meta;
        if (response.data.status === true) {
          this.subscriptions = response.data.data.data;
          paginationStore.setPage(current_page);
          paginationStore.setfrom(from);
          paginationStore.setlastpage(last_page);
          paginationStore.setperpage(per_page);
          paginationStore.setto(to);
          paginationStore.settotal(total);
          // console.log(this.subscriptions, "subscriptions");
        }
      } catch (error) {
        console.log("Error fetching subscriptions:", error);
      }
    },
    async deletesubscriptions(id) {
      try {
        const result = await Swal.fire({
          title: "هل انتا متاكد من عملية المسح?",
          text: "لن تتمكن من التراجع عن هذا!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم، احذفه!",
        });
        if (result.isConfirmed) {
          await axios.post("delete_subscripe_group", { id });
          this.subscriptions = this.subscriptions.filter(
            (subscriptions) => subscriptions.id !== id
          );
          Swal.fire("تم الحذف!", "تم حذف الاشتراك", "success");
        }
      } catch (error) {
        console.error("Error deleting subscriptions:", error);
        Swal.fire(
          "Error!",
          "There was an error deleting the subscriptions.",
          "error"
        );
      }
    },
  },
});
