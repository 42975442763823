import axios from "axios";
import { defineStore } from "pinia";
import router from "@/router";
import Swal from "sweetalert2";

export const useJopsEditStore = defineStore("jopsEdit", {
  state: () => ({
    jops: {},
  }),
  actions: {
    async getJops(id) {
      try {
        const response = await axios.post("fetch_job_type_details", { id });
        
        if (response.data.status) {
          this.jops = response.data.data;
        } else {
          console.error("Error fetching jobs:", response.data.message);
        }
      } catch (error) {
        console.error("API call failed:", error);
      }
    },

    async updateJops(formData ) {
      try {
        const response = await axios.post("edit_job_type", formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        
        if (response.data.status === true) {
          this.jops = response.data.data;
          Swal.fire({
            icon: "success",
            title: "تم التعديل بنجاح",
            text: response.data.message || "تم تعديل الوظيفه بنجاح",
          });
          await router.push("/index-jops");
        } else {
          Swal.fire({
            icon: "error",
            title: "خطأ",
            text: response.data.message,
          });
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "خطأ",
          text: "An error occurred while updating the job.",
        });
      }
    },
  },
});
