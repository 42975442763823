<template>
  <div class="add-Department">
    <div class="headers-add">
      <i class="fa-solid fa-plus add"></i>
    <HeaderPages title="إضافة قسم جديد" :showButton="false" />
    </div>
    <form @submit.prevent="submitForm">
      <div class="row">
        <div class="col-lg-7 col-md-7 col-12">
          <label for="name"> اسم القسم </label>
          <div class="input">
            <input
              type="text"
              v-model="form.title"
              id="name"
              placeholder="اكتب اسم القسم  "
            />
          </div>
          <span class="error-feedback" v-if="v$.form.title.$error">{{
              getErrorMessage(v$.form.title)
            }}</span>
        </div>
      </div>
      <div class="all-btn">
        <button type="submit" class="save">حفظ</button>
        <button type="button" class="bake" @click="$router.go(-1)">رجوع</button>
      </div>
    </form>
  </div>
</template>

<script>
import HeaderPages from "@/components/headerpages/HeaderPages.vue";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.css";
import { mapActions } from "pinia";
import { useBlogAddStore } from "@/stores/BlogCategory/BlogCategoryAddStore";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "EmployeesAdd",
  components: {
    HeaderPages,
    Multiselect,
  },
  data() {
    return {
      v$: useVuelidate(),
      form: {
        title: "",
      },
    };
  },
  validations() {
    return {
      form: {
        title: { required },
      },
    };
  },
  methods: {
    getErrorMessage(field) {
      if (field.$invalid && field.$dirty) {
        return "هذا الحقل مطلوب";
      }
      return "";
    },
    ...mapActions(useBlogAddStore, ["addBlogCategory"]),
    async submitForm() {
      this.v$.$validate();
      if (this.v$.$error) return;
      const isFormCorrect = await this.v$.$validate();
      if (!isFormCorrect) return;
      await this.addBlogCategory(this.form);
    },
  },
};
</script>
