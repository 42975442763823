import {defineStore} from "pinia";
import axios from "axios";
import Swal from "sweetalert2";
import router from "@/router";

export const useSubHeadersEditStore = defineStore("subHeadersEdit", {
    state: () => ({
        subHeaders: [],
    }),
    actions: {
        async fetchSubHeaders(id) {
            try {
                const response = await axios.post(
                    "organization_fetch_subheader_details",
                    {id}
                );

                if (response.data.status) {
                    this.subHeaders = response.data.data;
                    // Swal.fire({
                    //     icon: "success",
                    //     title: "تم التعديل بنجاح",
                    //     text: response.data.message || "تم تعديل العنوان بنجاح",
                    //   });
                } else {
                    Swal.fire({
                        icon: "error",
                        title: "خطأ",
                        text: response.data.message,
                      });                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "خطأ",
                    text: response.data.message,
                  });
            }
        },

        async updateSubHeaders(data, id) {
            try {
                const formData = new FormData();
                formData.append("id", id);
                formData.append("title", data.title);
                formData.append("subtitle", data.subtitle);
                formData.append("description", data.description);
                if (data.image) {
                    if (data.image instanceof File) {

                        formData.append("image", data.image); 
                    }
                }

                if (data.features && Array.isArray(data.features)) {
                    data.features.forEach((feature, index) => {
                        if (feature.file) {
                            if (feature.file instanceof File) {
                                formData.append(`features[${index}][image]`, feature.file);
                            }
                        }
                        if (feature.title) {
                            formData.append(`features[${index}][title]`, feature.title); 
                        }
                        if (feature.description) {
                            formData.append(
                                `features[${index}][description]`,
                                feature.description
                            );
                        }
                    });
                }

                const response = await axios.post(
                    "organization_edit_subheader",
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                        },
                    }
                );

                if (response.data.status) {
                    Swal.fire({
                        icon: "success",
                        title: "تم الحفظ بنجاح",
                        text: response.data.message || "تم تعديل العنوان بنجاح",
                      });
                    router.push("/whowe");
                } else {
                    throw new Error(response.data.message || "Unknown error");
                }
            } catch (error) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: error.message || "Something went wrong!",
                });
            }
        },
    },
});
