<template>
  <div v-if="showsidebar" class="main-side">
    <div
      :class="[
        'sidebar',
        localIsCollapsed ? 'sidebar-collapsed' : 'sidebar-expanded',
      ]"
      @mouseleave="closeSidebarOnLeave"
      @mouseover="openSidebarOnHover"
    >
      <ul class="sidebar-menu">
        <div class="navbar-toggle" @click="toggleSidebar">
          <i :class="isCollapsed ? 'fas fa-bars' : 'fas fa-times'"></i>
        </div>
        <li
          v-for="(item, index) in menuItems"
          :key="index"
          class="sidebar-item"
        >
          <!-- Menu Item Link -->
          <router-link
            v-if="!item.submenu"
            :to="item.route"
            class="sidebar-link"
            @click="closeSidebarOnItemClick"
          >
            <i :class="item.icon"></i>
            <span v-if="!localIsCollapsed">{{ item.name }}</span>
          </router-link>

          <!-- Submenu Item -->
          <div v-else>
            <div class="sidebar-link" @click="toggleSubmenu(index)">
              <i :class="item.icon"></i>
              <span v-if="!localIsCollapsed">{{ item.name }}</span>
              <i
                v-if="!localIsCollapsed"
                :class="
                  item.expanded ? 'fas fa-chevron-down' : 'fas fa-chevron-up'
                "
                class="up-down-icon"
                style="margin-inline-start: auto"
              ></i>
            </div>
            <ul v-if="item.expanded && !localIsCollapsed" class="submenu">
              <li
                v-for="(subItem, subIndex) in item.submenu"
                :key="subIndex"
                class="sidebar-subitem"
              >
                <router-link
                  :to="subItem.route"
                  class="sidebar-link"
                  @click="closeSidebarOnItemClick"
                >
                  <i :class="subItem.icon"></i>
                  <span>{{ subItem.name }}</span>
                </router-link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores/auth/AuthStore";
import { mapState } from "pinia";

export default {
  props: {
    isCollapsed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      localIsCollapsed: this.isCollapsed,
      toggleIcon: "fas fa-bars",
      menuItems: this.getMenuItems(),
    };
  },
  computed: {
    ...mapState(useAuthStore, {
      data: (state) => state.data.data,
    }),
    showsidebar() {
      return this.$route.path !== "/live-teacher";
    },
  },
  watch: {
    isCollapsed(newValue) {
      this.localIsCollapsed = newValue;
      this.updateToggleIcon();
    },
  },
  methods: {
    toggleSidebar() {
      this.localIsCollapsed = !this.localIsCollapsed;
      this.$emit("update:isCollapsed", this.localIsCollapsed);
      this.updateToggleIcon();
    },
    closeSidebarOnItemClick() {
      if (window.innerWidth <= 1024) {
        this.localIsCollapsed = true;
        this.$emit("update:isCollapsed", this.localIsCollapsed);
        this.updateToggleIcon();
      }
    },
    toggleSubmenu(index) {
      this.menuItems[index].expanded = !this.menuItems[index].expanded;
    },
    openSidebarOnHover() {
      if (this.localIsCollapsed && window.innerWidth > 1024) {
        this.localIsCollapsed = false;
        this.updateToggleIcon();
      }
    },
    closeSidebarOnLeave() {
      if (!this.localIsCollapsed && window.innerWidth > 1024) {
        this.localIsCollapsed = true;
        this.updateToggleIcon();
      }
    },
    handleResize() {
      if (window.innerWidth <= 600) {
        this.localIsCollapsed = true;
      } else if (window.innerWidth > 600 && window.innerWidth <= 1024) {
        this.localIsCollapsed = true;
      } else if (window.innerWidth > 1024 && !this.localIsCollapsed) {
        this.localIsCollapsed = false;
      }
      this.$emit("update:isCollapsed", this.localIsCollapsed);
      this.updateToggleIcon();
    },
    updateToggleIcon() {
      this.toggleIcon = this.localIsCollapsed ? "fas fa-bars" : "fas fa-times";
    },
    getMenuItems() {
      return [
        { name: "الرئيسيه", route: "/", icon: "fas fa-home" },
        {
          name: "الموظفين",
          route: "/employees",
          icon: "fa-solid fa-building-user",
        },
        {
          name: "التعليم",
          icon: "fa-brands fa-leanpub",
          submenu: [
            {
              name: "الدورات التعليمية",
              route: "/courses",
              icon: "fa-regular fa-clipboard",
            },
            {
              name: "النتائج",
              route: "/exam-results",
              icon: "fa-regular fa-clipboard",
            },
            {
              name: "المجموعات",
              route: "/groups",
              icon: "fa-regular fa-clipboard",
            },

            {
              name: "الأمتحانات",
              route: "/exams",
              icon: "fa-regular fa-clipboard",
            },
            {
              name: "بنك الاسئلة",
              route: "/index-question-banks",
              icon: "fa-regular fa-clipboard",
            },
          ],
        },
        {
          name: "المستخدمين",
          icon: "fa-solid fa-users-viewfinder",
          submenu: [
            {
              name: "الطلاب",
              route: "/student-index",
              icon: "fa-solid fa-user",
            },
            {
              name: "المعلمين ",
              route: "/teacher-index",
              icon: "fa-solid fa-person-chalkboard",
            },
            {
              name: "أولياء الامور",
              route: "/index-parent",
              icon: "fa-solid fa-people-roof",
            },
            {
              name: "صله القرابه",
              route: "/relation-index",
              icon: "fa-solid fa-people-roof",
            },
            {
              name: "المشرفين",
              route: "/index-moderators",
              icon: "fa-solid fa-users-gear",
            },
            {
              name: "الوظائف",
              route: "/index-jops",
              icon: "fa-solid fa-people-roof",
            },
          ],
        },
        {
          name: "الموقع الأكتروني",
          icon: "fa-solid fa-earth-americas",
          submenu: [
            {
              name: "سيكشن الهيدر ",
              route: "/index-header",
              icon: "fa-solid fa-section",
            },
            {
              name: "سيكشن الكاونتر ",
              route: "/index-counter",
              icon: "fa-solid fa-calculator",
            },
            {
              name: "الاسكرين",
              route: "/index-screen",
              icon: "fa-solid fa-solar-panel",
            },

            {
              name: "أراء العملاء ",
              route: "/opinion-client",
              icon: "fa-solid fa-sheet-plastic",
            },

            {
              name: "من نحن ",
              route: "/whowe",
              icon: "fa-solid fa-hand",
            },
            {
              name: "الخدمات",
              route: "/index-serves",
              icon: "fa-solid fa-server",
            },
            {
              name: "شركاء النجاح",
              route: "/index-successpartners",
              icon: "fa-solid fa-diagram-next",
            },
            {
              name: "الخدمات المميزه",
              route: "/index-features-services",
              icon: "fa-solid fa-server",
            },
            {
              name: "تطبيق المعلم",
              route: "/app-teacher",
              icon: "fa-solid fa-mobile-retro",
            },
            {
              name: "تطبيق الطالب",
              route: "/app-student",
              icon: "fa-solid fa-mobile-retro",
            },
            {
              name: "تطبيق ولي الأمر",
              route: "/app-parent",
              icon: "fa-solid fa-mobile-retro",
            },
          ],
        },
        {
          name: "المدونه",
          icon: "fa-solid fa-blog",
          submenu: [
            {
              name: " عرض المدونه",
              route: "/index-blog",
              icon: "fa-solid fa-user",
            },
            {
              name: " الأقسام ",
              route: "/index-department",
              icon: "fa-solid fa-person-chalkboard",
            },
            {
              name: " الهشتاج",
              route: "/index-hashtag",
              icon: "fa-solid fa-people-roof",
            },
          ],
        },
        // {
        //   name: "التقارير",
        //   icon: "fa-solid fa-clipboard",
        //   submenu: [
        //     {
        //       name: "  التقارير العامه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: " تقارير الأنجازات ",
        //       route: "/#",
        //       icon: "fa-solid fa-person-chalkboard",
        //     },
        //     {
        //       name: " تقارير الحضور والأنصراف",
        //       route: "/#",
        //       icon: "fa-solid fa-people-roof",
        //     },
        //     {
        //       name: "تقارير الأمتحانات",
        //       route: "/#",
        //       icon: "fa-solid fa-users-gear",
        //     },
        //     {
        //       name: "   تقارير المسابقات ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "    تقارير السلوكيه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "    تقارير السلوكيه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "    تقارير الاكادميه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "     التقارير الفائته ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "     التقارير التفصيليه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //     {
        //       name: "     التقارير الجمعيه أو المدرسه ",
        //       route: "/#",
        //       icon: "fa-solid fa-user",
        //     },
        //   ],
        // },
        // {
        //   name: "الأرشيف",
        //   route: "/#",
        //   icon: "fa-regular fa-newspaper",
        // },
        {
          name: "المسابقات",
          route: "/index-competion",
          icon: "fa-solid fa-trophy",
        },
        {
          name: "المكتبه الألكترونيه",
          route: "/index-electroniclibrary",
          icon: "fa-solid fa-book-open-reader",
        },
        {
          name: " الاقسام",
          route: "/index-category",
          icon: "fa-solid fa-list",
        },
        // {
        //   name: "الشكاوي",
        //   route: "/index-complaints",
        //   icon: "fa-solid fa-clipboard-question",
        // },
        {
          name: "الأشتراكات",
          route: "/index-subscription",
          icon: "fa-solid fa-thumbtack",
        },
        {
          name: "الصالحيات",
          route: "/index-permission",
          icon: "fa-solid fa-shield",
          // icon: "fa-regular fa-newspaper",
        },
        {
          name: "الاعدادات العامة",
          route: "/setting",
          icon: "fa-solid fa-gear",
        },
        {
          name: "الشكاوي ",
          route: "/index-complaints",
          icon: "fa-solid fa-handshake",
        },
      ];
    },
  },

  created() {
    this.localIsCollapsed = this.isCollapsed;
    this.updateToggleIcon();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>
<style scoped lang="scss">
.navbar-toggle {
  text-align: end;
  margin-top: 1rem;
  i {
    border-radius: 5px;
    border: 1px solid var(--main);
    padding: 0.5rem;
  }
}
.sidebar {
  transition: all 0.7s ease !important;
}
</style>
